import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import axios from "axios";
import MenuAppBar from "../Components/Header";
import moment from "moment";
import Drawer_customer from "../Components/Drawer";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import loader_img from "../Media/Business_chart.gif"
import Footer from "../Components/footer";
const { RangePicker } = DatePicker;

function Invoice({base_url}) {
  const storage = window.sessionStorage;
  let profile = JSON?.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  const [data, setData] = useState([]);
  const [invoice_count, setInvoice_Count] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);

  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  
  const [range_flag, setRange_Flag] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());
  const [params, setParams] = useState("");
  const [display2,setDisplay2] = useState(false)

  // load usestates

  const [invoice_load,setInvoice_Load] = useState(false)
  const [count_load,setCount_Load] = useState(false)

  const get_invoice = (temp_param) => {
    setInvoice_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", `${customer_id}`);
    // if(site_id!="" && site_id!= null){
    //   formData.append("site_id", site_id);
    //   } 
    if(temp_param != "custom"){
      formData.append("invoice_date", temp_param);
    }
    if(temp_param == "custom"){
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    }

    axios({
      url: `${base_url}/get-customer-invoice`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setInvoice_Load(false);
        setError(false);
        // setTotal_Amount(res.data?.invoice_sum_amount)
        // setPaid_Amount(res.data?.total_paid_amount)
        // setRemaining_Amount(res.data?.total_outstanding_amount)
        // setTotal_Suspense_Amount(res.data?.cust_suspend_amt)
        setData(res.data?.collection);
      })
      .catch((err) => {
        setInvoice_Load(false)
        setError(true);
        
      });
  };

  const get_invoice_count = () => {
    setCount_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("customer_id", `${customer_id}`);
    axios({
      url: `${base_url}/invoice-dashboard-count`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setCount_Load(false);
        setError(false);
        setInvoice_Count(res.data?.data[0]);
      })
      .catch((err) => {
        setCount_Load(false)
        setError(true);
        
      });
  };

  useEffect(() => {
    if(token != "" && token != null){
      get_invoice(params);
      get_invoice_count();
    }
  }, []);

  const handleDateRange = (e) => {
    setParams(e.target.value);
    if (e.target.value == "custom") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setSelectedFromDate();
      setSelectedToDate();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_invoice(e.target.value)
    }
  };

  const handleDateChange = (new_val)=>{
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
      let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      setStart_Date(newStartDate);
      setEnd_Date(newEndDate);
      setDisplay2(true)
      // setShowBtn(true);
    }else{
      setParams("")
      setRange_Flag(false)
      setSelectedFromDate();
      setSelectedToDate();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_invoice("")

      // dispatch(
      //   get_client_type_sample_revenue_data(
      //     setRevenue_Load,
      //     department,
      //     "today",
      //     start_date,
      //     end_date,
      //     setSample_Revenue_Data
      //   )
      // );
      setDisplay2(false)
    }
  }

  const handleClick = () => {
    if(selectedFromDate == undefined && selectedToDate == undefined){
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Date",
      });
    }else{
      get_invoice(params)
      }
 
  };

  const handleDownload =(row,data)=>{
    saveAs(row,data?.rowData[1]);
  }


  const columns = [
    {
      name: "invoice_date",
      label: "Invoice date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "invoice_no",
      label: "Invoice No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "site_name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,

      },
    },
 
 
    {
      name: "invoive_total",
      label: "Invoice Amount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p>{row == null?0:new Intl.NumberFormat("en-IN").format(row)}</p>;
        },
      },
    },
    {
      name: "payment_due_date",
      label: "due date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "payment_status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "invoice_files",
      label: "invoice files",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row,data)=>{
          return(
            <img
            src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
            style={{ height: "25px", marginLeft: "10%",cursor:"pointer"}}
            onClick={() => handleDownload(row,data)}
          />
          )
        }
      },
    }
  ];

  const options = {
    filterType: "checkbox",
  };

  //   if (header_name == "") {
  //     header_name = "All";
  //   }

  //   if (data == null) {
  //     return (
  //       <>
  //         <img src={nullData} alt="" id={styles.nullGif} />
  //       </>
  //     );
  //   }

  return (
    <>
      {/* <MenuAppBar/> */}
      <Drawer_customer/>
     {(invoice_load || count_load)?<div className="container-fluid"><img src={loader_img} id={styles.loader} /></div>: <div className="container-fluid" style={{paddingLeft:"5em"}}>
        <div className="row" id={styles.bills_info}>
          <div className="col-sm-12 col-md-3">
            <p>Month Till Date Billing</p>
            <div>
              <p id={styles.invoice_amounts}>
                INR{" "}
                {invoice_count?.month_till_date_billing == null
                  ? 0
                  : new Intl.NumberFormat("en-IN").format(
                      invoice_count?.month_till_date_billing
                    )}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <p>Total Billing Till Date</p>
            <div>
              <p id={styles.invoice_amounts}>
                INR{" "}
                {invoice_count?.total_billing_till_date == null
                  ? 0
                  : new Intl.NumberFormat("en-IN").format(
                      invoice_count?.total_billing_till_date
                    )}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <p>Bills Not Yet Due</p>
            <div>
              <p id={styles.invoice_amounts}>
                INR{" "}
                {invoice_count?.bills_not_yet_due == null
                  ? 0
                  : new Intl.NumberFormat("en-IN").format(
                      invoice_count?.bills_not_yet_due
                    )}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <p>Overdue Bills</p>
            <div>
              <p id={styles.overdue_amounts}>
                INR{" "}
                {invoice_count?.overdue_bill == null
                  ? 0
                  : new Intl.NumberFormat("en-IN").format(
                      invoice_count?.overdue_bill
                    )}
              </p>
            </div>
          </div>
        </div>
        <div className="row p-3">
          <MUIDataTable
            title={
              <h4 style={{textAlign:"left",fontSize:"16px"}} id={styles.list_head}>
                <DescriptionOutlinedIcon
                  sx={{
                    marginRight: "10px",
                    fontSize: "27px",
                    // marginTop: "-2px",
                    color: "#007cc3",
                  }}
                />
                Invoice List
              </h4>
            }
            
            data={data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              responsive: "standard",
              viewColumns: false,
              print: false,
              download: false,
              search: true,
              filter: true,
              sortOrder: {
                name: "invoice_date",
                direction: "desc",
               },
              rowsPerPageOptions: [10, 25, 50, 100],
              tableBodyMaxHeight: "40vh",
              customToolbar: () => {
                return (
                  <>
                    <div style={{ display: "inline-flex" }}>
                      <select
                        onChange={handleDateRange}
                        value={params}
                        className="form-select"
                        style={{ marginRight: "20px", width: "160px" }}
                      >
                        <option value="" selected >
                          All
                        </option>
                        {/* <option value="" selected disabled>
                          Please Select
                        </option> */}
              <option value="today">Today</option>
              <option value="month_till_date">Month Till Date</option>
              <option value="quarter_till_date">Quarter Till Date</option>
              <option value="last_quarter">Last Quarter</option>
              <option value="last_six_month">Last Six Month</option>
              <option value="custom">Custom</option>
                      </select>
                      {range_flag ? (
                        <>
                          <RangePicker
                            // defaultValue={dayjs(value, "YYYY-MM-DD")} format={dateFormat}
                            format={"DD-MM-YYYY"}
                            value={[selectedFromDate, selectedToDate]}
                            onChange={(new_val) => handleDateChange(new_val)}
                          />
                          <button
                            className="btn btn-primary btn-sm ml-2"
                            onClick={handleClick}
                            style={{
                              // display: display2 ? "flex" : "none",
                              marginLeft: "5px",
                              //   color:"#007cc3"
                            }}
                          >
                            Apply
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      </div>}
      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">

                    <p id={styles.copyright}>
          

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
               
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}

<Footer/>

      {/* <DrawerCloseButton open={open} setOpen={setOpen} /> */}
    </>
  );
}

export default Invoice;
