import React, { useCallback, useRef, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import ReactReadMoreReadLess from "react-read-more-read-less";
import styles from "../Styles/Audit.module.css";
import no_data_icon from "../Media/no_data_icon2.png"

function Scheduling_status({ data }) {
  const windowHeight = useRef(window.innerHeight);
  const [activeIndex, setActiveIndex] = useState(1);

  let dataArray = [[]];

  let pieData1 = [
    {
      id: 15200,
      value: Number(((data?.TOTAL_SITES / data?.TOTAL_SITES) * 100).toFixed(2)),
      name: "Total Audits created",
    },
    {
      id: 15450,
      value:
        100 -
        Number(((data?.TOTAL_SITES / data?.TOTAL_SITES) * 100).toFixed(2)),
      name: "",
    },
  ];
  let pieData2 = [
    {
      id: 15200,
      value: Number(
        (
          (data?.AUDITS_IN_VERIFICATION_STAGE / data?.TOTAL_SITES) *
          100
        ).toFixed(2)
      ),
      name: "Audit in verification stage",
    },
    {
      id: 15450,
      value:
        100 -
        Number(
          (
            (data?.AUDITS_IN_VERIFICATION_STAGE / data?.TOTAL_SITES) *
            100
          ).toFixed(2)
        ),
      name: "",
    },
  ];
  let pieData3 = [
    {
      id: 15200,
      value: Number(
        ((data?.AUDITS_IN_SCHEDULING_STAGE / data?.TOTAL_SITES) * 100).toFixed(
          2
        )
      ),
      name: "Audit in scheduling stage",
    },
    {
      id: 15450,
      value:
        100 -
        Number(
          (
            (data?.AUDITS_IN_SCHEDULING_STAGE / data?.TOTAL_SITES) *
            100
          ).toFixed(2)
        ),
      name: "",
    },
  ];
  let pieData4 = [
    {
      id: 15200,
      value: Number(
        ((data?.AUDITS_UNDER_QUALITY_CHECK / data?.TOTAL_SITES) * 100).toFixed(
          2
        )
      ),
      name: "Audit under quality check",
    },
    {
      id: 15450,
      value:
        100 -
        Number(
          (
            (data?.AUDITS_UNDER_QUALITY_CHECK / data?.TOTAL_SITES) *
            100
          ).toFixed(2)
        ),
      name: "",
    },
  ];
  let pieData5 = [
    {
      id: 15200,
      value: Number(
        ((data?.AUDITS_REPORT_GENERATED / data?.TOTAL_SITES) * 100).toFixed(2)
      ),
      name: "Total Report generated",
    },
    {
      id: 15450,
      value:
        100 -
        Number(
          ((data?.AUDITS_REPORT_GENERATED / data?.TOTAL_SITES) * 100).toFixed(2)
        ),
      name: "",
    },
  ];


  const renderActiveShape1 = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          style={{ fontSize: "17px", fontWeight: "700" }}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={"#1ea2ff"
            // pieData1[0]?.value >= 0 && pieData1[0]?.value < 30
            //   ? "#EB5757"
            //   : "rgba(235, 87, 87, 0.50)"
          }
        >
          {pieData1[0]?.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const onPieEnter1 = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape2 = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          style={{ fontSize: "17px", fontWeight: "700" }}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={"#FF9F1E"
            // pieData2[0]?.value >= 0 && pieData2[0]?.value < 30
            //   ? "#EB5757"
            //   : "rgba(235, 87, 87, 0.50)"
          }
        >
          {pieData2[0]?.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const onPieEnter2 = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape3 = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          style={{ fontSize: "17px", fontWeight: "700" }}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={"#f07c00"
            // pieData3[0]?.value >= 0 && pieData3[0]?.value < 30
            //   ? "#EB5757"
            //   : "rgba(235, 87, 87, 0.50)"
          }
        >
          {pieData3[0]?.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const onPieEnter3 = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape4 = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          style={{ fontSize: "17px", fontWeight: "700" }}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={"#d0b223"
            // pieData4[0]?.value >= 0 && pieData4[0]?.value < 30
            //   ? "#EB5757"
            //   : "rgba(235, 87, 87, 0.50)"
          }
        >
          {pieData4[0]?.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const onPieEnter4 = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape5 = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          style={{ fontSize: "17px", fontWeight: "700" }}
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={"#00c4f0"
            // pieData5[0]?.value >= 0 && pieData5[0]?.value < 30
            //   ? "#EB5757"
            //   : "rgba(235, 87, 87, 0.50)"
          }
        >
          {pieData5[0]?.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  const onPieEnter5 = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );





  const COLORS1 = [
    "#1ea2ff",
    "rgb(237 237 237)",
  ];
  const COLORS2 = [
    "#FF9F1E",
    "rgb(237 237 237)",
  ];
  const COLORS3 = [
    "#f07c00",
    "rgb(237 237 237)",
  ];
  const COLORS4 = [
    "#d0b223",
    "rgb(237 237 237)",
  ];
  const COLORS5 = [
    "#00c4f0",
    "rgb(237 237 237)",
  ];



  return (
    <>
      <div className="row" style={{marginTop:"3%", display:"flex",justifyContent:"center",pointerEvents:"none"}}>
        

            <div className="col-sm-12 col-md-2" >
               <PieChart width={300} height={300}>
              <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape1}
            data={pieData1}
            cx={85}
            cy={80}
            innerRadius={70}
            outerRadius={80}
            fill="#1ea2ff"
            dataKey="value"
            onMouseEnter={onPieEnter1}
          >
            {pieData1?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS1[index % COLORS1.length]}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              />
            ))}
          </Pie>
          
      <text
        style={{ fontSize: "14px", fontWeight: "700" }}
        x={90}
        y={200}
        dy={8}
        textAnchor="middle"
        fill={"black"}
      >
        {pieData1[0]?.name}
      </text>
      <text
        style={{ fontSize: "13px", fontWeight:"400" }}
        x={90}
        y={220}
        dy={8}
        textAnchor="middle"
        fill={"black"}
      >
        {data.TOTAL_AUDITS_CREATED}
      </text>
      </PieChart>
      </div>
          
          
       
        <div className="col-sm-12 col-md-2">

        {/* <ResponsiveContainer width="100%" height="100%"> */}
          <PieChart width={300} height={300}>
          
              <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape2}
              data={pieData2}
              // cx="50%" cy="30%" 
              cx={85}
              cy={80}
              innerRadius={70}
            outerRadius={80}
              // fill="#FF9F1E"
              dataKey="value"
              onMouseEnter={onPieEnter2}
            >
              {pieData2?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS2[index % COLORS2.length]}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                />
              ))}
            </Pie>
            <text
          style={{ fontSize: "14px", fontWeight: "700" }}
          x={90}
          y={200}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {pieData2[0]?.name}
        </text>
        <text
          style={{ fontSize: "13px", fontWeight:"400" }}
          x={90}
          y={220}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {data.AUDITS_IN_VERIFICATION_STAGE}
        </text>
        
       
            
            
          </PieChart>
          {/* </ResponsiveContainer> */}
          </div>
          <div className="col-sm-12 col-md-2">
          <PieChart width={300} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape3}
            data={pieData3}
            cx={85}
            cy={80}
            innerRadius={70}
            outerRadius={80}
            // fill="#f07c00"
            dataKey="value"
            onMouseEnter={onPieEnter3}
          >
            {pieData3?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS3[index % COLORS3.length]}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                />
              ))}
          </Pie>
          <text
          style={{ fontSize: "14px", fontWeight: "700" }}
          x={90}
          y={200}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {pieData3[0]?.name}
        </text>
        <text
          style={{ fontSize: "13px", fontWeight:"400" }}
          x={90}
          y={220}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {data.AUDITS_IN_SCHEDULING_STAGE}
        </text>
        </PieChart>
        </div>

<div className="col-sm-12 col-md-2">

        <PieChart width={300} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape4}
            data={pieData4}
            cx={85}
            cy={80}
            innerRadius={70}
            outerRadius={80}
            fill="#d0b223"
            dataKey="value"
            onMouseEnter={onPieEnter4}
          >
            {pieData4?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS4[index % COLORS4.length]}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              />
            ))}
          </Pie>
          {/* <p>{pieData4[0].name}</p> */}
          <text
          style={{ fontSize: "14px", fontWeight: "700" }}
          x={90}
          y={200}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {pieData4[0]?.name}
        </text>
        <text
          style={{ fontSize: "13px", fontWeight:"400" }}
          x={90}
          y={220}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {data.AUDITS_UNDER_QUALITY_CHECK}
        </text>
        </PieChart>
        </div>

        <div className="col-sm-12 col-md-2">
        <PieChart width={300} height={300}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape5}
            data={pieData5}
            cx={85}
            cy={80}
            innerRadius={70}
            outerRadius={80}
            fill="#00c4f0"
            dataKey="value"
            onMouseEnter={onPieEnter5}
          >
            {pieData5?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS5[index % COLORS5.length]}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              />
            ))}
          </Pie>
          <text
          style={{ fontSize: "14px", fontWeight: "700" }}
          x={90}
          y={200}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {pieData5[0]?.name}
        </text>
        <text
          style={{ fontSize: "13px", fontWeight:"400" }}
          x={90}
          y={220}
          dy={8}
          textAnchor="middle"
          fill={"black"}
        >
          {data.AUDITS_REPORT_GENERATED}
        </text>
        </PieChart>
        </div>

      </div>
    </>
  );
}

export default Scheduling_status;
