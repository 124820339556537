import React from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import styles from "../Styles/Audit.module.css"
import { saveAs } from "file-saver";

function Scheduling_Data_Table({
    tableData,
}) {


      const columns = [
        {
          name: "AUDIT_START_DATE",
          label: "Created Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "" || row == undefined) return <>--</>;
              return (
                <span style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </span>
              );
            },
          },
        },
        {
          name: "AUDIT_DONE_DATE",
          label: "Done Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "" || row == undefined) return <>--</>;
              return (
                <span style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </span>
              );
            },
          },
        },
    {
      name: "RES_ID",
      label: "Res Id",
      options: {
        filter: false,
        sort: false,
        // display: false,
        download: false,
      },
    },
    {
      name: "Site_Name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (row, data) => {
        //   if (row == null || row == "") return <></>;
        //   return (
        //     <p style={{ textAlign: "center" }}>
        //       {moment(row).format("DD MMM YYYY")}
        //     </p>
        //   );
        // },
      },
    },
    {
      name: "CURRENT_STATE_ID",
      label: "State",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (row, data) => {
        //   if (row == null || row == "") return <></>;
        //   return (
        //     <p style={{ textAlign: "center" }}>
        //       {moment(row).format("DD MMM YYYY")}
        //     </p>
        //   );
        // },
      },
    },
    {
      name: "CURRENT_CITY_ID",
      label: "City",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (row, data) => {
        //   if (row == null || row == "") return <></>;
        //   return (
        //     <p style={{ textAlign: "center" }}>
        //       {moment(row).format("DD MMM YYYY")}
        //     </p>
        //   );
        // },
      },
    },
    {
      name: "AUDIT_SCORE",
      label: "Audit Score",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "10px" }}>{row}</p>;
        },
      },
    },
    {
      name: "AUDIT_RESULT",
      label: "Audit Result",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "0px" }}>{row}</p>;
        },
      },
    },
    {
      name: "REMARK",
      label: "Remark",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "0px" }}>{row}</p>;
        },
      },
    },
    // {
    //   name: "Client",
    //   label: "Project Name",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "Audit_Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "0px" }}>{row}</p>;
        },
      },
    },
    {
      name: "REPORT_LOCATION",
      label: "Download",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row,data) => {
          if(row == ""){
            return<></>
          }
          return        <img
          src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
          onClick={()=>saveAs(row)}
          style={{
            height: "20px",
            // marginLeft: "10%",
            cursor: "pointer",
          }}
        />;
        },
      },
    },

   
  ];

  const options = {
    filterType: "checkbox",
  };



  return (
    <>
    
         <div style={{paddingBottom:"2%"}}>
      <MUIDataTable
        title={
          <h4 id={styles.list_head}>
           Audit Details
          </h4>
        }
        data={tableData}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: true,
          filter: true,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,
          // onRowClick: (rowData) => {
          //   handleRow(rowData);
          // },

          // sortOrder: {
          //   name: "date_of_collection",
          //   direction: "desc",
          // },
        
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
        }}
      />
          </div>
    
    </>
  )
}

export default Scheduling_Data_Table