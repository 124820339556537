import React, { useEffect, useState } from "react";
import MenuAppBar from "../Components/Header";
import styles from "../Styles/Services.module.css";
import Swal from "sweetalert2";
import axios from "axios";
import { Tooltip } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Drawer_customer from "../Components/Drawer";
import loader_img from "../Media/Business_chart.gif";
import Schedule_tbl from "../Components/Schedule_tbl";

const { RangePicker } = DatePicker;

function Services({ base_url }) {
  const storage = window.sessionStorage;
  let profile = JSON.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  let customer_name = storage.getItem("customer_name");
  const [params, setParams] = useState("");

  let support_details = JSON?.parse(storage.getItem("support_details"));
const [year_heat_map, setYear_Heat_Map] = useState(new Date());
let info_arr = year_heat_map?.toString()?.split(" ");

  const [service_data, setService_Data] = useState([]);
  const [service_header, setService_Header] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [send_from_date, setSend_from_Date] = useState("");
  const [send_to_date, setSend_to_Date] = useState("");
  const [displayDate1, setDisplayDate1] = useState(false);

  const [date_show, setDate_Show] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  // schedule useStates

  const [schedule_params, setSchedule_Params] = useState("");
  const [range_flag, setRange_Flag] = useState(false);
  const [selectedFromDate_schedule, setSelectedFromDate_Schedule] = useState();
  const [selectedToDate_schedule, setSelectedToDate_Schedule] = useState();
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());
  const [display2, setDisplay2] = useState(false);
  const [schedule_list, setSchedule_List] = useState([]);

  const [schedule_load, setSchedule_Load] = useState(false);
  const [heat_map_load, setHeat_Map_Load] = useState(false);


  const [total_count,setTotal_Count] = useState([])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const get_service_heat_map = async (val) => {
    setHeat_Map_Load(true);
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    // if (site_id != "" && site_id != null) {
    //   formData.append("site_id", site_id);
    // }
    // if (val != "custom") {
    //   formData.append("collected_date", val);
    // }
    // if (val == "custom") {
    //   formData.append("start_date", send_from_date);
    //   formData.append("end_date", send_to_date);
    // }

    axios({
      url: `${base_url}/customer-service-heatmap`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setHeat_Map_Load(false);
        if (res?.data?.code == "200") {
          setService_Data(res?.data?.data[0]);
          setService_Header(res?.data?.data[1]);
          setTotal_Count(res?.data?.data[2]);         
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setHeat_Map_Load(false);
        
        setService_Data([]);
        setService_Header([]);
      });
  };

  const get_schedule_list = (temp_param) => {
    setSchedule_Load(true);
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    if (site_id != "" && site_id != null) {
      formData.append("site_id", site_id);
    }
    if (temp_param != "custom") {
      formData.append("scheduled_date", temp_param);
    }
    if (temp_param == "custom") {
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
    }

    axios({
      url: `${base_url}/customer-dashboard-scheduled-list`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setSchedule_Load(false);
        if (res?.data?.code == "200") {
          setSchedule_List(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setSchedule_Load(false);
        
      });
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_service_heat_map(params);
      // get_schedule_list(schedule_params);
    }
  }, []);

  const handleParameter = (e) => {
    setParams(e.target.value);
    if (e.target.value == "custom") {
      setDate_Show(true);
    } else {
      setDate_Show(false);
      get_service_heat_map(e.target.value);
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedFromDate(dayjs(dates[0]?.$d));
      setSelectedToDate(dayjs(dates[1]?.$d));
      setSend_from_Date(moment(dates[0].$d).format("YYYY-MM-DD"));
      setSend_to_Date(moment(dates[1].$d).format("YYYY-MM-DD"));
      setDisplayDate1(true);
    } else {
      setSelectedFromDate(null);
      setSelectedToDate(null);
      setSend_from_Date("");
      setSend_to_Date("");
      setDisplayDate1(false);
      setDate_Show(false);
      setParams("");
      get_service_heat_map("");
    }
  };

  const handle_date_change = (e) => {
    if (selectedFromDate == undefined && selectedToDate == undefined) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Date",
      });
    } else {
      get_service_heat_map(params);
      setDisplayDate1(false);
    }
  };

  const handleDateRange_schedule = (e) => {
    setSchedule_Params(e.target.value);
    if (e.target.value == "custom") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setSelectedFromDate_Schedule();
      setSelectedToDate_Schedule();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_schedule_list(e.target.value);
    }
  };

  const handleDateChange_schedule = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate_Schedule(new_val[0]);
      setSelectedToDate_Schedule(new_val[1]);
      let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      setStart_Date(newStartDate);
      setEnd_Date(newEndDate);
      setDisplay2(true);
      // setShowBtn(true);
    } else {
      setSchedule_Params("");
      setRange_Flag(false);
      setSelectedFromDate_Schedule();
      setSelectedToDate_Schedule();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_schedule_list("");
      setDisplay2(false);
    }
  };

  const handle_click_schedule = () => {
    if (
      selectedFromDate_schedule == undefined &&
      selectedToDate_schedule == undefined
    ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Date",
      });
    } else {
      get_schedule_list(params);
    }
  };

  let x = service_data[0]?.Continuous_Ambient_Noise_24_hrs_Testing_planned;

  return (
    <>
      {/* <MenuAppBar header_name={customer_name} /> */}
      <Drawer_customer />
      {heat_map_load ? (
        <div className="container-fluid">
          <img id={styles.loader} src={loader_img} />
        </div>
      ) : (
        <div
          className="container-fluid"
          style={{ paddingLeft: "5em", paddingRight: "1em" }}
        >
          {/* <div className="row" style={{display:"flex",justifyContent:"end"}}>

          <div className="col-2">
            <select
              id={styles.selectstyle}
              value={params}
              className="form-select"
              name=""
   
              onChange={handleParameter}
            >
                                  <option value="" selected >
                      All
                    </option>

              <option value="today">Today</option>
              <option value="month_till_date">Month Till Date</option>
              <option value="quarter_till_date">Quarter Till Date</option>
              <option value="last_quarter">Last Quarter</option>
              <option value="last_six_month">Last Six Month</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          {date_show ? (
            <div
              className="col-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Space direction="vertical" size={12}>
                <RangePicker
                  format="DD-MM-YYYY"
                  value={[selectedFromDate, selectedToDate]}
                  onChange={onRangeChange}
                />
              </Space>
              <button
                className="btn btn-primary btn-sm"
                style={{
                  display: displayDate1 ? "flex" : "none",
                  marginLeft: "10px",
                }}
                onClick={handle_date_change}
              >
                Apply
              </button>
            </div>
          ) : (
            <></>
          )}

        </div> */}
          {service_data?.map((ele) => (
            <div className="mt-4" style={{ display: "flex", gap: "30px" }}>
              <div className={`${styles.serviceName}`}>
                <p>{ele?.site_name}</p>
              </div>
              <div>
                <div>
                  <div className={styles.unit_contain}>
                  <div style={{width:"80px",marginTop:"5%"}}> <p>Planned</p> </div>
                    <div
                      style={{
                        display:
                          ele?.Air_Corrosion_Testing_collected == 0 &&
                          ele?.Air_Corrosion_Testing_scheduled == 0 &&
                          ele?.Air_Corrosion_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[0]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[0]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Air_Corrosion_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_collected ==
                            0 &&
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled ==
                            0 &&
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[1]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[1]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Air_Quality_Monitoring_8Hrs_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Air_Quality_Testing_collected == 0 &&
                          ele?.Ambient_Air_Quality_Testing_scheduled == 0 &&
                          ele?.Ambient_Air_Quality_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[2]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[2]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Air_Quality_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Noise_8_Hrs_collected == 0 &&
                          ele?.Ambient_Noise_8_Hrs_scheduled == 0 &&
                          ele?.Ambient_Noise_8_Hrs_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[3]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[3]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Noise_8_Hrs_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Audit_collected == 0 &&
                          ele?.Audit_scheduled == 0 &&
                          ele?.Audit_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[4]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[4]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Audit_planned}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Beverages_Testing_collected == 0 &&
                          ele?.Beverages_Testing_scheduled == 0 &&
                          ele?.Beverages_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[5]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[5]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Beverages_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Boiler_Stack_Testing_collected == 0 &&
                          ele?.Boiler_Stack_Testing_scheduled == 0 &&
                          ele?.Boiler_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[6]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[6]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Boiler_Stack_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Compliance_collected == 0 &&
                          ele?.Compliance_scheduled == 0 &&
                          ele?.Compliance_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[7]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[7]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Compliance_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Compressed_Air_Testing_collected == 0 &&
                          ele?.Compressed_Air_Testing_scheduled == 0 &&
                          ele?.Compressed_Air_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[8]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[8]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Compressed_Air_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_collected ==
                            0 &&
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_scheduled ==
                            0 &&
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_planned ==
                            0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[9]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[9]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Continuous_Ambient_Noise_24_hrs_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.DG_stack_Testing_collected == 0 &&
                          ele?.DG_stack_Testing_scheduled == 0 &&
                          ele?.DG_stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[10]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[10]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.DG_stack_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Effluent_Water_Testing_collected == 0 &&
                          ele?.Effluent_Water_Testing_scheduled == 0 &&
                          ele?.Effluent_Water_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[11]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[11]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Effluent_Water_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Environment_Testing_collected == 0 &&
                          ele?.Environment_Testing_scheduled == 0 &&
                          ele?.Environment_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[12]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[12]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Environment_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Chemical_Testing_collected == 0 &&
                          ele?.Food_Chemical_Testing_scheduled == 0 &&
                          ele?.Food_Chemical_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[13]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[13]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Chemical_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Founders_Club_collected == 0 &&
                          ele?.Food_Founders_Club_scheduled == 0 &&
                          ele?.Food_Founders_Club_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[14]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[14]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Founders_Club_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Microbiology_Testing_collected == 0 &&
                          ele?.Food_Microbiology_Testing_scheduled == 0 &&
                          ele?.Food_Microbiology_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[15]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[15]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Microbiology_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Testing_collected == 0 &&
                          ele?.Food_Testing_scheduled == 0 &&
                          ele?.Food_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[16]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[16]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Fuel_Testing_collected == 0 &&
                          ele?.Fuel_Testing_scheduled == 0 &&
                          ele?.Fuel_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[17]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[17]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Fuel_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Furnace_Stack_Testing_collected == 0 &&
                          ele?.Furnace_Stack_Testing_scheduled == 0 &&
                          ele?.Furnace_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[18]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[18]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Furnace_Stack_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Hourly_Ambient_Noise_24__Testing_collected ==
                            0 &&
                          ele?.Hourly_Ambient_Noise_24__Testing_scheduled ==
                            0 &&
                          ele?.Hourly_Ambient_Noise_24__Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[19]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[19]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Hourly_Ambient_Noise_24__Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Incinerator_Stack_Testing_collected == 0 &&
                          ele?.Incinerator_Stack_Testing_scheduled == 0 &&
                          ele?.Incinerator_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[20]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[20]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Incinerator_Stack_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Indoor_Air_Quality_Testing_collected == 0 &&
                          ele?.Indoor_Air_Quality_Testing_scheduled == 0 &&
                          ele?.Indoor_Air_Quality_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[21]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[21]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Indoor_Air_Quality_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Light_Intensity_Testing_collected == 0 &&
                          ele?.Light_Intensity_Testing_scheduled == 0 &&
                          ele?.Light_Intensity_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[22]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[22]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Light_Intensity_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Logistics_collected == 0 &&
                          ele?.Logistics_scheduled == 0 &&
                          ele?.Logistics_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[23]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[23]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Logistics_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Migration_Testing_collected == 0 &&
                          ele?.Migration_Testing_scheduled == 0 &&
                          ele?.Migration_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[24]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[24]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Migration_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Monitoring_collected == 0 &&
                          ele?.Monitoring_scheduled == 0 &&
                          ele?.Monitoring_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[25]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[25]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Monitoring_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Noise_Testing_collected == 0 &&
                          ele?.Noise_Testing_scheduled == 0 &&
                          ele?.Noise_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[26]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[26]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Noise_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Nutrition_Value_Calculation_collected == 0 &&
                          ele?.Nutrition_Value_Calculation_scheduled == 0 &&
                          ele?.Nutrition_Value_Calculation_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[27]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[27]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Nutrition_Value_Calculation_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Nutritional_Label_collected == 0 &&
                          ele?.Nutritional_Label_scheduled == 0 &&
                          ele?.Nutritional_Label_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[28]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[28]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Nutritional_Label_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Other_collected == 0 &&
                          ele?.Other_scheduled == 0 &&
                          ele?.Other_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[29]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[29]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Other_planned}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Others_collected == 0 &&
                          ele?.Others_scheduled == 0 &&
                          ele?.Others_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[30]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[30]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Others_planned}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Shelf_Life_Testing_collected == 0 &&
                          ele?.Shelf_Life_Testing_scheduled == 0 &&
                          ele?.Shelf_Life_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[31]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[31]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Shelf_Life_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Soil_Testing_collected == 0 &&
                          ele?.Soil_Testing_scheduled == 0 &&
                          ele?.Soil_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[32]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[32]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Soil_Testing_planned}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Spot_Noise_Testing_collected == 0 &&
                          ele?.Spot_Noise_Testing_scheduled == 0 &&
                          ele?.Spot_Noise_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[33]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[33]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Spot_Noise_Testing_planned}
                      </div>
                    </div>
                    <div  style={{
                        display:
                          ele?.Swab_Testing_collected == 0 &&
                          ele?.Swab_Testing_scheduled == 0 &&
                          ele?.Swab_Testing_planned == 0
                            ? "none"
                            : "block",
                      }} className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[34]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[34]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Swab_Testing_planned}
                      </div>
                    </div>
                    <div style={{
                        display:
                          ele?.Training_collected == 0 &&
                          ele?.Training_scheduled == 0 &&
                          ele?.Training_planned == 0
                            ? "none"
                            : "block",
                      }} className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[35]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[35]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Training_planned}</div>
                    </div>

                    <div
                    style={{
                      display:
                        ele?.Water_Testing_collected == 0 &&
                        ele?.Water_Testing_scheduled == 0 &&
                        ele?.Water_Testing_planned == 0
                          ? "none"
                          : "block",
                    }}
                    className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[36]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[36]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Water_Testing_planned}
                      </div>
                    </div>

                    <div
                    style={{
                      display:
                        ele?.Work_Place_Monitoring_collected == 0 &&
                        ele?.Work_Place_Monitoring_scheduled == 0 &&
                        ele?.Work_Place_Monitoring_planned == 0
                          ? "none"
                          : "block",
                    }}
                    className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[37]?.name}> */}
                        <span className={styles.monthTitle}>
                          {service_header[37]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Work_Place_Monitoring_planned}
                      </div>
                    </div>
                  </div>
            
                  <div className={styles.unit_contain}>
                 <div style={{width:"80px",marginTop:"5%"}}> <p>Scheduled</p></div>
                    <div
                      style={{
                        display:
                          ele?.Air_Corrosion_Testing_collected == 0 &&
                          ele?.Air_Corrosion_Testing_scheduled == 0 &&
                          ele?.Air_Corrosion_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[0]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[0]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Air_Corrosion_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_collected ==
                            0 &&
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled ==
                            0 &&
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[1]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[1]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Air_Quality_Testing_collected == 0 &&
                          ele?.Ambient_Air_Quality_Testing_scheduled == 0 &&
                          ele?.Ambient_Air_Quality_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[2]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[2]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Air_Quality_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Noise_8_Hrs_collected == 0 &&
                          ele?.Ambient_Noise_8_Hrs_scheduled == 0 &&
                          ele?.Ambient_Noise_8_Hrs_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[3]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[3]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Noise_8_Hrs_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Audit_collected == 0 &&
                          ele?.Audit_scheduled == 0 &&
                          ele?.Audit_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[4]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[4]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Audit_scheduled}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Beverages_Testing_collected == 0 &&
                          ele?.Beverages_Testing_scheduled == 0 &&
                          ele?.Beverages_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[5]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[5]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Beverages_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Boiler_Stack_Testing_collected == 0 &&
                          ele?.Boiler_Stack_Testing_scheduled == 0 &&
                          ele?.Boiler_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[6]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[6]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Boiler_Stack_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Compliance_collected == 0 &&
                          ele?.Compliance_scheduled == 0 &&
                          ele?.Compliance_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[7]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[7]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Compliance_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Compressed_Air_Testing_collected == 0 &&
                          ele?.Compressed_Air_Testing_scheduled == 0 &&
                          ele?.Compressed_Air_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[8]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[8]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Compressed_Air_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_collected ==
                            0 &&
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_scheduled ==
                            0 &&
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_planned ==
                            0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[9]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[9]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Continuous_Ambient_Noise_24_hrs_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.DG_stack_Testing_collected == 0 &&
                          ele?.DG_stack_Testing_scheduled == 0 &&
                          ele?.DG_stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[10]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[10]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.DG_stack_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Effluent_Water_Testing_collected == 0 &&
                          ele?.Effluent_Water_Testing_scheduled == 0 &&
                          ele?.Effluent_Water_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[11]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[11]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Effluent_Water_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Environment_Testing_collected == 0 &&
                          ele?.Environment_Testing_scheduled == 0 &&
                          ele?.Environment_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[12]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[12]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Environment_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Chemical_Testing_collected == 0 &&
                          ele?.Food_Chemical_Testing_scheduled == 0 &&
                          ele?.Food_Chemical_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[13]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[13]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Chemical_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Founders_Club_collected == 0 &&
                          ele?.Food_Founders_Club_scheduled == 0 &&
                          ele?.Food_Founders_Club_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[14]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[14]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Founders_Club_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Microbiology_Testing_collected == 0 &&
                          ele?.Food_Microbiology_Testing_scheduled == 0 &&
                          ele?.Food_Microbiology_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[15]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[15]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Microbiology_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Testing_collected == 0 &&
                          ele?.Food_Testing_scheduled == 0 &&
                          ele?.Food_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[16]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[16]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Fuel_Testing_collected == 0 &&
                          ele?.Fuel_Testing_scheduled == 0 &&
                          ele?.Fuel_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[17]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[17]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Fuel_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Furnace_Stack_Testing_collected == 0 &&
                          ele?.Furnace_Stack_Testing_scheduled == 0 &&
                          ele?.Furnace_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[18]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[18]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Furnace_Stack_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Hourly_Ambient_Noise_24__Testing_collected ==
                            0 &&
                          ele?.Hourly_Ambient_Noise_24__Testing_scheduled ==
                            0 &&
                          ele?.Hourly_Ambient_Noise_24__Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[19]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[19]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Hourly_Ambient_Noise_24__Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Incinerator_Stack_Testing_collected == 0 &&
                          ele?.Incinerator_Stack_Testing_scheduled == 0 &&
                          ele?.Incinerator_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[20]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[20]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Incinerator_Stack_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Indoor_Air_Quality_Testing_collected == 0 &&
                          ele?.Indoor_Air_Quality_Testing_scheduled == 0 &&
                          ele?.Indoor_Air_Quality_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[21]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[21]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Indoor_Air_Quality_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Light_Intensity_Testing_collected == 0 &&
                          ele?.Light_Intensity_Testing_scheduled == 0 &&
                          ele?.Light_Intensity_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[22]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[22]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Light_Intensity_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Logistics_collected == 0 &&
                          ele?.Logistics_scheduled == 0 &&
                          ele?.Logistics_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[23]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[23]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Logistics_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Migration_Testing_collected == 0 &&
                          ele?.Migration_Testing_scheduled == 0 &&
                          ele?.Migration_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[24]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[24]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Migration_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Monitoring_collected == 0 &&
                          ele?.Monitoring_scheduled == 0 &&
                          ele?.Monitoring_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[25]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[25]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Monitoring_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Noise_Testing_collected == 0 &&
                          ele?.Noise_Testing_scheduled == 0 &&
                          ele?.Noise_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[26]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[26]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Noise_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Nutrition_Value_Calculation_collected == 0 &&
                          ele?.Nutrition_Value_Calculation_scheduled == 0 &&
                          ele?.Nutrition_Value_Calculation_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[27]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[27]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Nutrition_Value_Calculation_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Nutritional_Label_collected == 0 &&
                          ele?.Nutritional_Label_scheduled == 0 &&
                          ele?.Nutritional_Label_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[28]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[28]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Nutritional_Label_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Other_collected == 0 &&
                          ele?.Other_scheduled == 0 &&
                          ele?.Other_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[29]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[29]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Other_scheduled}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Others_collected == 0 &&
                          ele?.Others_scheduled == 0 &&
                          ele?.Others_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[30]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[30]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Others_scheduled}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Shelf_Life_Testing_collected == 0 &&
                          ele?.Shelf_Life_Testing_scheduled == 0 &&
                          ele?.Shelf_Life_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[31]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[31]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Shelf_Life_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Soil_Testing_collected == 0 &&
                          ele?.Soil_Testing_scheduled == 0 &&
                          ele?.Soil_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[32]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[32]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Soil_Testing_scheduled}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Spot_Noise_Testing_collected == 0 &&
                          ele?.Spot_Noise_Testing_scheduled == 0 &&
                          ele?.Spot_Noise_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[33]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[33]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Spot_Noise_Testing_scheduled}
                      </div>
                    </div>
                    <div  style={{
                        display:
                          ele?.Swab_Testing_collected == 0 &&
                          ele?.Swab_Testing_scheduled == 0 &&
                          ele?.Swab_Testing_planned == 0
                            ? "none"
                            : "block",
                      }} className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[34]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[34]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Swab_Testing_scheduled}
                      </div>
                    </div>
                    <div style={{
                        display:
                          ele?.Training_collected == 0 &&
                          ele?.Training_scheduled == 0 &&
                          ele?.Training_planned == 0
                            ? "none"
                            : "block",
                      }} className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[35]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[35]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Training_scheduled}</div>
                    </div>

                    <div
                    style={{
                      display:
                        ele?.Water_Testing_collected == 0 &&
                        ele?.Water_Testing_scheduled == 0 &&
                        ele?.Water_Testing_planned == 0
                          ? "none"
                          : "block",
                    }}
                    className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[36]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[36]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Water_Testing_scheduled}
                      </div>
                    </div>

                    <div
                    style={{
                      display:
                        ele?.Work_Place_Monitoring_collected == 0 &&
                        ele?.Work_Place_Monitoring_scheduled == 0 &&
                        ele?.Work_Place_Monitoring_planned == 0
                          ? "none"
                          : "block",
                    }}
                    className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[37]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[37]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Work_Place_Monitoring_scheduled}
                      </div>
                    </div>
                  </div>

                  <div className={styles.unit_contain}>
                  <div style={{width:"80px",marginTop:"5%"}}> <p>Collected</p></div>
                    <div
                      style={{
                        display:
                          ele?.Air_Corrosion_Testing_collected == 0 &&
                          ele?.Air_Corrosion_Testing_scheduled == 0 &&
                          ele?.Air_Corrosion_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[0]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[0]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Air_Corrosion_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_collected ==
                            0 &&
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled ==
                            0 &&
                          ele?.Ambient_Air_Quality_Monitoring_8Hrs_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[1]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[1]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Air_Quality_Monitoring_8Hrs_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Air_Quality_Testing_collected == 0 &&
                          ele?.Ambient_Air_Quality_Testing_scheduled == 0 &&
                          ele?.Ambient_Air_Quality_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[2]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[2]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Air_Quality_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Ambient_Noise_8_Hrs_collected == 0 &&
                          ele?.Ambient_Noise_8_Hrs_scheduled == 0 &&
                          ele?.Ambient_Noise_8_Hrs_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[3]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[3]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Ambient_Noise_8_Hrs_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Audit_collected == 0 &&
                          ele?.Audit_scheduled == 0 &&
                          ele?.Audit_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[4]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[4]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Audit_collected}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Beverages_Testing_collected == 0 &&
                          ele?.Beverages_Testing_scheduled == 0 &&
                          ele?.Beverages_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[5]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[5]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Beverages_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Boiler_Stack_Testing_collected == 0 &&
                          ele?.Boiler_Stack_Testing_scheduled == 0 &&
                          ele?.Boiler_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[6]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[6]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Boiler_Stack_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Compliance_collected == 0 &&
                          ele?.Compliance_scheduled == 0 &&
                          ele?.Compliance_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[7]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[7]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Compliance_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Compressed_Air_Testing_collected == 0 &&
                          ele?.Compressed_Air_Testing_scheduled == 0 &&
                          ele?.Compressed_Air_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[8]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[8]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Compressed_Air_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_collected ==
                            0 &&
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_scheduled ==
                            0 &&
                          ele?.Continuous_Ambient_Noise_24_hrs_Testing_planned ==
                            0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[9]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[9]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Continuous_Ambient_Noise_24_hrs_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.DG_stack_Testing_collected == 0 &&
                          ele?.DG_stack_Testing_scheduled == 0 &&
                          ele?.DG_stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[10]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[10]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.DG_stack_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Effluent_Water_Testing_collected == 0 &&
                          ele?.Effluent_Water_Testing_scheduled == 0 &&
                          ele?.Effluent_Water_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[11]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[11]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Effluent_Water_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Environment_Testing_collected == 0 &&
                          ele?.Environment_Testing_scheduled == 0 &&
                          ele?.Environment_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[12]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[12]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Environment_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Chemical_Testing_collected == 0 &&
                          ele?.Food_Chemical_Testing_scheduled == 0 &&
                          ele?.Food_Chemical_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[13]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[13]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Chemical_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Founders_Club_collected == 0 &&
                          ele?.Food_Founders_Club_scheduled == 0 &&
                          ele?.Food_Founders_Club_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[14]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[14]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Founders_Club_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Microbiology_Testing_collected == 0 &&
                          ele?.Food_Microbiology_Testing_scheduled == 0 &&
                          ele?.Food_Microbiology_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[15]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[15]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Microbiology_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Food_Testing_collected == 0 &&
                          ele?.Food_Testing_scheduled == 0 &&
                          ele?.Food_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[16]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[16]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Food_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Fuel_Testing_collected == 0 &&
                          ele?.Fuel_Testing_scheduled == 0 &&
                          ele?.Fuel_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[17]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[17]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Fuel_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Furnace_Stack_Testing_collected == 0 &&
                          ele?.Furnace_Stack_Testing_scheduled == 0 &&
                          ele?.Furnace_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[18]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[18]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Furnace_Stack_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Hourly_Ambient_Noise_24__Testing_collected ==
                            0 &&
                          ele?.Hourly_Ambient_Noise_24__Testing_scheduled ==
                            0 &&
                          ele?.Hourly_Ambient_Noise_24__Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[19]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[19]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Hourly_Ambient_Noise_24__Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Incinerator_Stack_Testing_collected == 0 &&
                          ele?.Incinerator_Stack_Testing_scheduled == 0 &&
                          ele?.Incinerator_Stack_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[20]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[20]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Incinerator_Stack_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Indoor_Air_Quality_Testing_collected == 0 &&
                          ele?.Indoor_Air_Quality_Testing_scheduled == 0 &&
                          ele?.Indoor_Air_Quality_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[21]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[21]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Indoor_Air_Quality_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Light_Intensity_Testing_collected == 0 &&
                          ele?.Light_Intensity_Testing_scheduled == 0 &&
                          ele?.Light_Intensity_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[22]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[22]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Light_Intensity_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Logistics_collected == 0 &&
                          ele?.Logistics_scheduled == 0 &&
                          ele?.Logistics_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[23]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[23]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Logistics_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Migration_Testing_collected == 0 &&
                          ele?.Migration_Testing_scheduled == 0 &&
                          ele?.Migration_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[24]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[24]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Migration_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Monitoring_collected == 0 &&
                          ele?.Monitoring_scheduled == 0 &&
                          ele?.Monitoring_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[25]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[25]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Monitoring_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Noise_Testing_collected == 0 &&
                          ele?.Noise_Testing_scheduled == 0 &&
                          ele?.Noise_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[26]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[26]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Noise_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Nutrition_Value_Calculation_collected == 0 &&
                          ele?.Nutrition_Value_Calculation_scheduled == 0 &&
                          ele?.Nutrition_Value_Calculation_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[27]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[27]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Nutrition_Value_Calculation_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Nutritional_Label_collected == 0 &&
                          ele?.Nutritional_Label_scheduled == 0 &&
                          ele?.Nutritional_Label_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[28]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[28]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Nutritional_Label_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Other_collected == 0 &&
                          ele?.Other_scheduled == 0 &&
                          ele?.Other_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[29]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[29]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Other_collected}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Others_collected == 0 &&
                          ele?.Others_scheduled == 0 &&
                          ele?.Others_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[30]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[30]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Others_collected}</div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Shelf_Life_Testing_collected == 0 &&
                          ele?.Shelf_Life_Testing_scheduled == 0 &&
                          ele?.Shelf_Life_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[31]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[31]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Shelf_Life_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Soil_Testing_collected == 0 &&
                          ele?.Soil_Testing_scheduled == 0 &&
                          ele?.Soil_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[32]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[32]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Soil_Testing_collected}
                      </div>
                    </div>
                    <div
                      style={{
                        display:
                          ele?.Spot_Noise_Testing_collected == 0 &&
                          ele?.Spot_Noise_Testing_scheduled == 0 &&
                          ele?.Spot_Noise_Testing_planned == 0
                            ? "none"
                            : "block",
                      }}
                      className={`${styles.unitDiv}`}
                    >
                      {/* <Tooltip title={service_header[33]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[33]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Spot_Noise_Testing_collected}
                      </div>
                    </div>
                    <div  style={{
                        display:
                          ele?.Swab_Testing_collected == 0 &&
                          ele?.Swab_Testing_scheduled == 0 &&
                          ele?.Swab_Testing_planned == 0
                            ? "none"
                            : "block",
                      }} className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[34]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[34]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Swab_Testing_collected}
                      </div>
                    </div>
                    <div style={{
                        display:
                          ele?.Training_collected == 0 &&
                          ele?.Training_scheduled == 0 &&
                          ele?.Training_planned == 0
                            ? "none"
                            : "block",
                      }} className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[35]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[35]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>{ele?.Training_collected}</div>
                    </div>

                    <div
                    style={{
                      display:
                        ele?.Water_Testing_collected == 0 &&
                        ele?.Water_Testing_scheduled == 0 &&
                        ele?.Water_Testing_planned == 0
                          ? "none"
                          : "block",
                    }}
                    className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[36]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[36]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Water_Testing_collected}
                      </div>
                    </div>

                    <div
                    style={{
                      display:
                        ele?.Work_Place_Monitoring_collected == 0 &&
                        ele?.Work_Place_Monitoring_scheduled == 0 &&
                        ele?.Work_Place_Monitoring_planned == 0
                          ? "none"
                          : "block",
                    }}
                    className={`${styles.unitDiv}`}>
                      {/* <Tooltip title={service_header[37]?.name}> */}
                        <span className={styles.monthTitle2}>
                          {service_header[37]?.name}
                        </span>
                      {/* </Tooltip> */}
                      <div className={styles.unit1}>
                        {ele?.Work_Place_Monitoring_collected}
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          ))}

          {/* <div className="row mt-5 mb-4">
    <Schedule_tbl 
                        handleDateRange={handleDateRange_schedule}
                        range_flag={range_flag}
                        selectedFromDate={selectedFromDate_schedule}
                        selectedToDate={selectedToDate_schedule}
                        handleDateChange = {handleDateChange_schedule}
                        data={schedule_list}
                        handleClick={handle_click_schedule}
                        display2={display2}
                        params_schedule={schedule_params}
    />
</div> */}
        </div>
      )}
          <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
                  {/* <div style={{position:"absolute", bottom: "0px",right: "40%"}}> */}
                    <p id={styles.copyright}>
                      {/* Copyright : Equinox Labs Pvt ltd-{info_arr[3]} */}

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
                  {/* </div> */}
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div>
    </>
  );
}

export default Services;

{
  /* <div className="mt-3" style={{ display: "flex", gap: "50px",alignItems: "flex-end" }}>
<div className={`${styles.serviceName}`}>
  <p>{ele?.site_name}</p>
</div>
<div>
  <div className={styles.unit_contain}>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[0]?.name}>
      <span className={styles.monthTitle}>
         Au
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor: ele?.Audit != 0 ? "#007cc3" : "",
          color: ele?.Audit != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Audit}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[1]?.name}>
      <span className={styles.monthTitle}>
Com
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor: ele?.Compliance != 0 ? "#007cc3" : "",
          color: ele?.Compliance != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Compliance}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[2]?.name}>
      <span className={styles.monthTitle}>
        Env
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor: ele?.Environment != 0 ? "#007cc3" : "",
          color: ele?.Environment != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Environment}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[3]?.name}>
      <span className={styles.monthTitle}>
       FT
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor:
            ele?.Food_Testing != 0 ? "#007cc3" : "",
          color: ele?.Food_Testing != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Food_Testing}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[4]?.name}>
      <span className={styles.monthTitle}>
       Mon
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor: ele?.Monitoring != 0 ? "#007cc3" : "",
          color: ele?.Monitoring != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Monitoring}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[5]?.name}>
      <span className={styles.monthTitle}>
    NV
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor:
            ele?.Nutritional_Value != 0 ? "#007cc3" : "",
          color: ele?.Nutritional_Value != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Nutritional_Value}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      // <Tooltip title={service_header[6]?.name}>
      <span className={styles.monthTitle}>
       ST
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor:
            ele?.Swab_Testing != 0 ? "#007cc3" : "",
          color: ele?.Swab_Testing != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Swab_Testing}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      <Tooltip title={service_header[7]?.name}>
      <span className={styles.monthTitle}>
       T
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor: ele?.Training != 0 ? "#007cc3" : "",
          color: ele?.Training != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Training}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      <Tooltip title={service_header[8]?.name}>
      <span className={styles.monthTitle}>
      WT
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor:
            ele?.Water_Testing != 0 ? "#007cc3" : "",
          color: ele?.Water_Testing != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Water_Testing}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      <Tooltip title={service_header[9]?.name}>
      <span className={styles.monthTitle}>
       OT
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor: ele?.Other != 0 ? "#007cc3" : "",
          color: ele?.Other != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Other}
      </div>
    </div>
    <div className={`${styles.unitDiv}`}>
      <Tooltip title={service_header[10]?.name}>
      <span className={styles.monthTitle}>
      FFE
      </span>
      </Tooltip>
      <div
        style={{
          backgroundColor:
            ele?.Food_Founders_Ecosystem != 0 ? "#007cc3" : "",
          color: ele?.Food_Founders_Ecosystem != 0 ? "white" : "",
        }}
        className={styles.unit1}
      >
        {ele?.Food_Founders_Ecosystem}
      </div>
    </div>
  </div>
</div>
</div> */
}

//           <div className="mt-4" style={{ display: "flex", gap: "30px" }}>
//             <div className={`${styles.serviceName}`}>
//               <p>{ele?.site_name}</p>
//             </div>
//             <div>
//               <div className={styles.unit_contain}>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[0]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[0]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Water_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[1]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[1]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>
//                     {ele?.Food_Microbiology_Testing}
//                   </div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[2]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[2]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Environment_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[3]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[3]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Swab_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[4]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[4]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Training}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[5]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[5]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Nutrition_Value_Calculation}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[6]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[6]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Audit}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[7]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[7]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Compliance}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[8]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[8]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Monitoring}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[9]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[9]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Indoor_Air_Quality_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[10]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[10]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Other}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[11]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[11]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Logistics}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[12]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[12]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Nutritional_Label}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[13]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[13]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Shelf_Life_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[14]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[14]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Food_Chemical_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[15]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[15]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Noise_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[16]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[16]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Migration_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[17]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[17]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Food_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[18]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[18]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Ambient_Air_Quality_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[19]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[19]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Compressed_Air_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[20]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[20]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Work_Place_Monitoring}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[21]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[21]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Air_Corrosion_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[22]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[22]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.DG_stack_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[23]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[23]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Boiler_Stack_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[24]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[24]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Furnace_Stack_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[25]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[25]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Incinerator_Stack_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[26]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[26]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Continuous_Ambient_Noise_24_hrs_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[27]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[27]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Spot_Noise_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[28]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[28]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Light_Intensity_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[29]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[29]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Beverages_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[30]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[30]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Effluent_Water_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[31]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[31]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Soil_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[32]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[32]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Fuel_Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[33]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[33]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Hourly_Ambient_Noise_24__Testing}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[34]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[34]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Others}</div>
//                 </div>
//                 <div className={`${styles.unitDiv}`}>
//                   <Tooltip title={service_header[35]?.name}>
//                     <span className={styles.monthTitle}>
//                       {service_header[35]?.abbreviation}
//                     </span>
//                   </Tooltip>
//                   <div className={styles.unit1}>{ele?.Food_Founders_Club}</div>
//                 </div>
//                 {/* <div className={`${styles.unitDiv}`}>
// <Tooltip title={service_header[36]?.name}><span className={styles.monthTitle}>{service_header[36]?.abbreviation}</span></Tooltip>
//         <div className={styles.unit1}></div>
//       </div>
//       <div className={`${styles.unitDiv}`}>
// <Tooltip title={service_header[37]?.name}><span className={styles.monthTitle}>{service_header[37]?.abbreviation}</span></Tooltip>
//         <div className={styles.unit1}></div>
//       </div> */}
//               </div>
//             </div>
//           </div>

{
  /* <div id={styles.main}>
          {service_data?.map((ele,index) => (
            <div className="mt-3" style={{ display: "flex", gap: "50px",alignItems: "flex-end" }}>
              <div className={`${styles.serviceName}`}>
                <p>{ele?.site_name}</p>
              </div>
              <div>
                <div className={styles.unit_contain}>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[0]?.name}>
                 {index == 0? <span className={styles.monthTitle}>
                       Au
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor: ele?.Audit != 0 ? "#007cc3" : "",
                        color: ele?.Audit != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Audit}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[1]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
            Com
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor: ele?.Compliance != 0 ? "#007cc3" : "",
                        color: ele?.Compliance != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Compliance}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[2]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
                      Env
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor: ele?.Environment != 0 ? "#007cc3" : "",
                        color: ele?.Environment != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Environment}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[3]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
                     FT
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor:
                          ele?.Food_Testing != 0 ? "#007cc3" : "",
                        color: ele?.Food_Testing != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Food_Testing}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[4]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
                     Mon
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor: ele?.Monitoring != 0 ? "#007cc3" : "",
                        color: ele?.Monitoring != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Monitoring}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[5]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
                  NV
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor:
                          ele?.Nutritional_Value != 0 ? "#007cc3" : "",
                        color: ele?.Nutritional_Value != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Nutritional_Value}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[6]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
                     ST
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor:
                          ele?.Swab_Testing != 0 ? "#007cc3" : "",
                        color: ele?.Swab_Testing != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Swab_Testing}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[7]?.name}>
                    {index == 0?   <span className={styles.monthTitle}>
                     T
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor: ele?.Training != 0 ? "#007cc3" : "",
                        color: ele?.Training != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Training}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[8]?.name}>
                    {index == 0?   <span className={styles.monthTitle}>
                    WT
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor:
                          ele?.Water_Testing != 0 ? "#007cc3" : "",
                        color: ele?.Water_Testing != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Water_Testing}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[9]?.name}>
                    {index == 0? <span className={styles.monthTitle}>
                     OT
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor: ele?.Other != 0 ? "#007cc3" : "",
                        color: ele?.Other != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Other}
                    </div>
                  </div>
                  <div className={`${styles.unitDiv}`}>
                    <Tooltip title={service_header[10]?.name}>
                    {index == 0?  <span className={styles.monthTitle}>
                    FFE
                    </span>:<></>}
                    </Tooltip>
                    <div
                      style={{
                        backgroundColor:
                          ele?.Food_Founders_Ecosystem != 0 ? "#007cc3" : "",
                        color: ele?.Food_Founders_Ecosystem != 0 ? "white" : "",
                      }}
                      className={styles.unit1}
                    >
                      {ele?.Food_Founders_Ecosystem}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */
}
