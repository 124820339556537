import React, { useEffect, useState } from "react";
import Drawer_customer from "../Components/Drawer";
import styles from "../Styles/Leads.module.css";
import Leads_table from "../Components/Leads_table";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PersonIcon from "@mui/icons-material/Person";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import moment from "moment";
import loader_img from "../Media/Business_chart.gif";
import Iris_footer from "../Components/Iris_footer";

function Leads_audit_data() {
  const storage = window.sessionStorage;

  const [isSelected, setIsSelected] = useState(false);
  const [leads_count_data, setLeads_Count_Data] = useState([]);
  const [leads_data, setLeads_Data] = useState([]);
  const [audit_data, setAudit_Data] = useState([]);

  const [audit_count_data, setAudit_Count_Data] = useState([]);
  const [leads_total_count,setLeads_Total_Count] = useState(0)

  // const [state_list, setState_List] = useState([]);
  // const [selected_state, setSelected_State] = useState("ALL");
  const [city_list, setCity_List] = useState([]);
  const [selected_City, setSelected_City] = useState("ALL");
  const [status_list, setStatus_List] = useState([]);
  const [selected_Status, setSelected_Status] = useState("ALL");

  const [status_list_audit, setStatus_List_Audit] = useState([]);
  const [city_list_audit, setCity_List_Audit] = useState([]);
  const [selected_city_audit, setSelected_City_Audit] = useState("ALL");
  const [selected_status_audit, setSelected_Status_Audit] = useState("ALL");
  const [progress_data, setProgress_Data] = useState([]);
  const [audit_total_count, setAudit_total_Count] = useState(0);

  const [selected_agreement, setSelected_agreement] = useState("ALL");

  const [more_btn_disable, setMore_Btn_disable] = useState(false);

  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");

  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);

  const [page, setPage] = useState(0);
  const [page_audit, setPage_audit] = useState(0);

  const [leads_count_load, setLeads_Count_Load] = useState(false);
  const [leads_data_load, setLeads_Data_Load] = useState(false);
  const [leads_load_more, setLeads_Load_More] = useState(false);

  const [audit_count_load, setaudit_Count_Load] = useState(false);
  const [audit_data_load, setAudit_Data_Load] = useState(false);
  const [audit_progress_load, setAudit_Progress_Load] = useState(false);
  const [audits_load_more, setAudits_Load_More] = useState(false);
  const [audit_total_load, setAudit_Total_Load] = useState(false);

  const [same_tab_click,setSame_Tab_Click] = useState(false)

  const get_leads_tab_count = async () => {
    setLeads_Count_Load(true);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_LEADS_COUNT/ALL/ALL`
      );
      let data = await res.json();
      setLeads_Count_Load(false);
      setLeads_Count_Data(data?.data[0]);
    } catch (error) {
      setLeads_Count_Load(false);
    }
  };

  const get_audit_tab_count = async () => {
    setaudit_Count_Load(true);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_AUDITS_COUNT/44d11731-b6db-4312-8cd9-a306cac6fc27/ALL/ALL`
      );
      let data = await res.json();
      setaudit_Count_Load(false);
      setAudit_Count_Data(data?.data[0]);
    } catch (error) {
      setaudit_Count_Load(false);
    }
  };
  // const get_state_list = async () => {
  //   try {
  //     let res = await fetch(
  //       `https://crm.equinoxlab.com/Equinox_Center/State_City_Master.svc/get_city_state/state/`
  //     );
  //     let data = await res.json();
  //     setState_List(data?.data);
  //   } catch (error) {}
  // };

  const get_status_list = async () => {
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api//Status_Master.svc/get_status_select2/calling`
      );
      let data = await res.json();
      setStatus_List(data?.data);
    } catch (error) {}
  };

  const get_status_list_audit = async () => {
    try {
      let res = await fetch(
        `https://irisauditor.com/api/Status_Master.svc/get_status_select2/all`
      );
      let data = await res.json();
      setStatus_List_Audit(data?.data);
    } catch (error) {}
  };

  const get_city_list = async (val) => {
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api/User_Address.svc/get_City_By_Multiple_State/all`
      );
      let data = await res.json();
      setCity_List(data?.data);
    } catch (error) {}
  };

  const get_city_list_audit = async (val) => {
    try {
      let res = await fetch(
        `https://irisauditor.com/universal_api/State_City_Master.svc/get_city_state/city/all`
      );
      let data = await res.json();
      setCity_List_Audit(data?.data);
    } catch (error) {}
  };

  const get_all_lead_data = async (signal) => {
    setLeads_Data([]);
    setLeads_Data_Load(true);
    setMore_Btn_disable(false);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_ALL_LEADS/${send_month}/${send_year}/10/0/${selected_Status}/${selected_City}`,
        { signal: signal }
      );
      let data = await res.json();
      setLeads_Data_Load(false);
      setLeads_Data(data?.data);
      setLeads_Total_Count(data?.TOTAL_ROW_COUNT)
      if (
        data?.data?.length == Number(data?.TOTAL_ROW_COUNT) ||
        data?.data?.length > Number(data?.TOTAL_ROW_COUNT)
      ) {
        setMore_Btn_disable(true);
      }
    } catch (error) {
      setLeads_Data_Load(false);
    }
  };

  const get_more_leads = async (latest_page) => {
    setLeads_Load_More(true);
    setMore_Btn_disable(false);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_ALL_LEADS/${send_month}/${send_year}/10/${latest_page}/${selected_Status}/${selected_City}`
      );
      let data = await res.json();
      setLeads_Load_More(false);
      let val = leads_data.concat(data?.data);
      setLeads_Data(val);
      setLeads_Total_Count(data?.TOTAL_ROW_COUNT)
      if (
        val?.length == Number(data?.TOTAL_ROW_COUNT) ||
        val?.length > Number(data?.TOTAL_ROW_COUNT)
      ) {
        setMore_Btn_disable(true);
      }
    } catch (error) {
      setLeads_Load_More(false);
    }
  };

  const get_all_audit_data = async (signal) => {
    setAudit_Data([]);
    setAudit_Data_Load(true);
    setMore_Btn_disable(false);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_ALL_AUDITS`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          // signal:"signal",
          body: JSON.stringify({
            MONTH: send_month,
            YEAR: send_year,
            SHOW: "10",
            PAGE: "0",
            // PAGE: page_audit,
            STATUS: selected_status_audit,
            CITY: selected_city_audit,
            TOA: selected_agreement,
            CLIENT_MASTER_PHONE_NO: "44d11731-b6db-4312-8cd9-a306cac6fc27",
            // "signal":signal
          }),
          signal,
        }
      );
      let data = await res.json();
      setAudit_Data_Load(false);
      setAudit_Data(data?.data);
      setAudit_total_Count(data?.TOTAL_ROW_COUNT)
      if (
        data?.data?.length == Number(data?.TOTAL_ROW_COUNT) ||
        data?.data?.length > Number(data?.TOTAL_ROW_COUNT)
      ) {
        setMore_Btn_disable(true);
      }
    } catch (error) {
      setAudit_Data_Load(false);
    }
  };

  const get_more_audits = async (latest_page) => {
    setAudits_Load_More(true);
    setMore_Btn_disable(false);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_ALL_AUDITS`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            MONTH: send_month,
            YEAR: send_year,
            SHOW: "10",
            PAGE: latest_page,
            STATUS: selected_status_audit,
            CITY: selected_city_audit,
            TOA: selected_agreement,
            CLIENT_MASTER_PHONE_NO: "44d11731-b6db-4312-8cd9-a306cac6fc27",
          }),
        }
      );
      let data = await res.json();
      setAudits_Load_More(false);
      setAudit_total_Count(data?.TOTAL_ROW_COUNT)
      let val = audit_data.concat(data?.data);
      setAudit_Data(val);
      if (
        val?.length == Number(data?.TOTAL_ROW_COUNT) ||
        val?.length > Number(data?.TOTAL_ROW_COUNT)
      ) {
        setMore_Btn_disable(true);
      }
    } catch (error) {
      setAudits_Load_More(false);
    }
  };

  const get_audit_progress_data = async () => {
    setAudit_Progress_Load(true);
    try {
      let res = await fetch(
        `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_TYPE_OF_AGREEMENTS_COUNT`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            MONTH: send_month,
            YEAR: send_year,
            SHOW: "10",
            PAGE: page_audit,
            STATUS: selected_status_audit,
            CITY: selected_city_audit,
            TOA: selected_agreement,
            CLIENT_MASTER_PHONE_NO: "44d11731-b6db-4312-8cd9-a306cac6fc27",
          }),
        }
      );
      let data = await res.json();
      setAudit_Progress_Load(false);
      setProgress_Data(data?.data[0]);
    } catch (error) {
      setAudit_Progress_Load(false);
    }
  };

  const get_audit_total_count = async () => {
    setAudit_Total_Load(true);
    try {
        let res = await fetch(
          `https://crm.equinoxlab.com/api1/Admin_Dashboard.svc/get_CLIENT_CENTRAL_ALL_AUDITS_ROW_COUNT`,
          {
            method: "POST",
            headers: { "Content-Type": "Application/Json" },
            body: JSON.stringify({
              MONTH: send_month,
              YEAR: send_year,
              SHOW: "10",
              PAGE: page_audit,
              STATUS: selected_status_audit,
              CITY: selected_city_audit,
              TOA: selected_agreement,
              CLIENT_MASTER_PHONE_NO: "44d11731-b6db-4312-8cd9-a306cac6fc27",
            }),
          }
        );
      let data = await res.json();
      setAudit_total_Count(data?.TOTAL_ROW_COUNT)

      if (
        audit_data?.length == data?.TOTAL_ROW_COUNT ||
        audit_data?.length > data?.TOTAL_ROW_COUNT
      ) {
 
        setMore_Btn_disable(true);
      }
      setAudit_Total_Load(false);
    } catch (error) {
      setAudit_Total_Load(false);
    }
  };

  // const handleState = (e) => {
  //   setSelected_State(e.target.value);
  //   get_city_list(e.target.value);
  // };

  const handleShow_More = () => {
    if (!isSelected) {
      setPage(page + 1);
      get_more_leads(page + 1);
    } else {
      setPage_audit(page_audit + 1);
      get_more_audits(page_audit + 1);
    }
  };

  const handleMonth = (date, dateString) => {
    if (date) {
      setSelectedMonth(date);
      let formattedMon = moment(date?.$d)?.format("MM");
      let formattedYear = moment(date?.$d)?.format("YYYY");
      setSend_year(formattedYear);
      setSend_month(formattedMon);
    } else {
      const formattedMonth = dayjs(new Date());
      let new_month = moment(formattedMonth.$d).format("MM");
      let new_year = moment(formattedMonth.$d).format("YYYY");
      setSend_year(new_year);
      setSend_month(new_month);
      setSelectedMonth(formattedMonth);
    }
  };

  useEffect(() => {
    if (!isSelected) {
      get_leads_tab_count();
      get_status_list();
      get_city_list();
      setAudit_Data_Load(false)
    } else {
      get_status_list_audit();
      get_city_list_audit();
      get_audit_tab_count();
      setLeads_Data_Load(false)
    }
    setSelected_City("ALL");
    setSelected_Status("ALL");
    setSelected_agreement("ALL");
    setSelected_Status_Audit("ALL");
    setSelected_City_Audit("ALL")
    const formattedMonth = dayjs(new Date());
    let new_month = moment(formattedMonth.$d).format("MM");
    let new_year = moment(formattedMonth.$d).format("YYYY");
    setSend_year(new_year);
    setSend_month(new_month);
    setSelectedMonth(formattedMonth);
    // setLeads_Data([]);
    // setAudit_Data([])
    setMore_Btn_disable(false);
  }, [isSelected
    // ,same_tab_click
 ]);

  useEffect(() => {
    if (!isSelected) {
      setPage(0)
      const controller = new AbortController();
      const signal = controller.signal;
      get_all_lead_data(signal);
      return () => {
        controller.abort();
      };
    }
  }, [isSelected, selected_Status, selected_City, send_month,
    // same_tab_click
  ]);

  useEffect(() => {
    if (isSelected) {
      setPage_audit(0)
      const controller = new AbortController();
      const signal = controller.signal;
      get_audit_progress_data();
      get_all_audit_data(signal);
      // get_audit_total_count();

      return () => {
        controller.abort();
      };
    }
  }, [
    isSelected,
    selected_city_audit,
    selected_status_audit,
    selected_agreement,
    send_month,
  ]);

  useEffect(()=>{
   let audit_flag = storage.getItem("audit_flag");

   if(audit_flag == "show_audit"){
    setIsSelected(true)
   }else{
    setIsSelected(false)
   }
   
  },[])


const handleTab = ()=>{
  // if(isSelected == false){
  //   setSame_Tab_Click(!same_tab_click)
  // }9
   setIsSelected(false)
}

  return (
    <>
      <Drawer_customer />
      {leads_count_load || audit_count_load ? (
        <div
          // {(leads_count_load || leads_data_load || audit_count_load || audit_data_load || audit_progress_load)?<div
          className="container-fluid"
          style={{
            paddingLeft: "5em",
            marginTop: "-2.5em",
            paddingRight: "1em",
          }}
        >
          <img id={styles.loader} src={loader_img} />
        </div>
      ) : (
        <div
          className="container-fluid"
          style={{
            paddingLeft: "5em",
            marginTop: "-2.5em",
            paddingRight: "1em",
          }}
        >
          <div className="row mt-2">
            <div className="col-2">
              <div className="row">
                <div
                  id={!isSelected ? styles.selected_tab : styles.header_tab}
                  // onClick={() => setIsSelected(false)}
                  onClick={handleTab}
                  className="col-6"
                >
                  <h6>Leads</h6>
                </div>
                <div
                  id={isSelected ? styles.selected_tab : styles.header_tab}
                  onClick={() => setIsSelected(true)}
                  className="col-6"
                >
                  <h6>Audits</h6>
                </div>
              </div>
            </div>
          </div>

          {!isSelected ? (
            <div className="row mt-4" id={styles.tab_container}>
              <div className="col-sm-6 col-md-2" style={{ marginLeft: "15px" }}>
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <SummarizeIcon
                      sx={{ color: "#f27519" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Total Leads</p>
                    <p id={styles.value_tab}>
                      {leads_count_data?.TOTAL_LEAD_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <ImportExportIcon
                      sx={{ color: "#4f22fb" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Connected</p>
                    <p id={styles.value_tab}>
                      {leads_count_data?.TOTAL_CONNECTED_CALL_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <PersonIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Not Interested</p>
                    <p id={styles.value_tab}>
                      {leads_count_data?.TOTAL_NOT_INTERESTED_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <PersonIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Agreed</p>
                    <p id={styles.value_tab}>
                      {leads_count_data?.TOTAL_AGREED_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <PersonIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Pending</p>
                    <p id={styles.value_tab}>
                      {leads_count_data?.TOTAL_PENDING_COUNT}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="row mt-4"
              style={{ paddingLeft: "10px" }}
              id={styles.tab_container}
            >
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <SummarizeIcon
                      sx={{ color: "#f27519" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Agreed</p>
                    <p id={styles.value_tab}>
                      {audit_count_data?.AGREED_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <ImportExportIcon
                      sx={{ color: "#4f22fb" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Pending</p>
                    <p id={styles.value_tab}>
                      {audit_count_data?.PENDING_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <PersonIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Scheduled</p>
                    <p id={styles.value_tab}>
                      {audit_count_data?.SCHEDULED_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <PersonIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Audit Done</p>
                    <p id={styles.value_tab}>
                      {audit_count_data?.AUDIT_DONE_COUNT}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <ThumbUpIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Pass</p>
                    <p id={styles.value_tab}>{audit_count_data?.PASS_COUNT}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-2">
                <div className="row">
                  <div className="col-3" id={styles.icon_container}>
                    <CancelIcon
                      sx={{ color: "#1cade6" }}
                      id={styles.tab_icon}
                    />
                  </div>
                  <div className="col-9">
                    <p id={styles.title_tab}>Fail</p>
                    <p id={styles.value_tab}>{audit_count_data?.FAIL_COUNT}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-5">
            {leads_load_more ||
            audits_load_more ||
            leads_data_load ||
            audit_data_load ||
            audit_progress_load 
            // || audit_total_load
             ? (
              <img id={styles.loader_more} src={loader_img} />
            ) : (
              <Leads_table
                data={isSelected ? audit_data : leads_data}
                // state_list={state_list}
                // handleState={handleState}
                // selected_state={selected_state}
                city_list={city_list}
                selected_City={selected_City}
                setSelected_City={setSelected_City}
                status_list={status_list}
                selected_Status={selected_Status}
                setSelected_Status={setSelected_Status}
                handleMonth={handleMonth}
                selectedMonth={selectedMonth}
                city_list_audit={city_list_audit}
                selected_city_audit={selected_city_audit}
                setSelected_City_Audit={setSelected_City_Audit}
                status_list_audit={status_list_audit}
                selected_status_audit={selected_status_audit}
                setSelected_Status_Audit={setSelected_Status_Audit}
                selected_agreement={selected_agreement}
                setSelected_agreement={setSelected_agreement}
                progress_data={progress_data}
                isSelected={isSelected}
                total_count={isSelected ? audit_total_count : leads_total_count}
              />
            )}
          </div>
          <div className="row mt-2 mb-4">
            {leads_load_more ||
            audits_load_more ||
            leads_data_load ||
            audit_data_load ||
            audit_progress_load 
            // ||audit_total_load
             ? (
              <></>
            ) : (
              <button
                onClick={handleShow_More}
                id={styles.load_btn}
                disabled={more_btn_disable}
              >
                Load More
              </button>
            )}
          </div>
        </div>
      )}
            <Iris_footer />
    </>
  );
}

export default Leads_audit_data;
