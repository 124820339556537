import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Drawer_customer from "../Components/Drawer";
import styles from "../Styles/Service_avails.module.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Services_avails({ base_url }) {
  const storage = window.sessionStorage;
  let profile = JSON.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  let customer_name = storage.getItem("customer_name");
  const [params, setParams] = useState("");

  const [service_data, setService_Data] = useState([]);
  const [service_header, setService_Header] = useState([]);

  const [heat_map_load, setHeat_Map_Load] = useState(false);
  const [air_corrosion_testing, setAir_Corrosion_testing] = useState(0);
  const [food_micro_test, setFood_Micro_Test] = useState(0);
  const [ambient_air_monitor, setAmbient_air_monitor] = useState(0);
  const [ambient_air_quality, setAmbient_air_quality] = useState(0);
  const [ambient_noise, setAmbient_noise] = useState(0);
  const [audit,setAudit] = useState(0)
  const [beverage,setBeverage] = useState(0)
  const [boiler,setBoiler] = useState(0)
  const [compliance,setCompliance] = useState(0)
  const [compressed,setcompressed] = useState(0)
  const [continuous,setcontinuous] = useState(0)
  const [dg,setdg] = useState(0)
  const [effluent,seteffluent] = useState(0)
  const [environment,setenvironment] = useState(0)
  const [food_chemical,setfood_chemical] = useState(0)
  const [food_founder,setfood_founder] = useState(0)
  const [food_micro,setfood_micro] = useState(0)
  const [food_test,setFood_Test] = useState(0)
  const [fuel_test,setFuel_Test] = useState(0)
  const [furnace,setfurnace] = useState(0)
  const [hourly,sethourly] = useState(0)
  const [Incinerator,setIncinerator] = useState(0)
  const [indoor,setindoor] = useState(0)
  const [light,setlight] = useState(0)
  const [logistics,setlogistics] = useState(0)
  const [migration,setmigration] = useState(0)
  const [monitoring,setmonitoring] = useState(0)
  const [noise,setnoise] = useState(0)
  const [nutrition_value,setnutrition_value] = useState(0)
  const [nutrition_label,setnutrition_label] = useState(0)
  const [other,setother] = useState(0)
  const [others,setothers] = useState(0)
  const [shelf,setshelf] = useState(0)
  const [soil,setsoil] = useState(0)
  const [swab,setswab] = useState(0)
  const [training,settraining] = useState(0)
  const [water,setwater] = useState(0)
  const [work,setwork] = useState(0)


  const check_data = (data)=>{

    let air_corrosion_testing = 0;
    let food_micro_test = 0;
    let ambient_air_monitor = 0;
    let ambient_air_quality = 0;
    let ambient_noise = 0;
    let audit = 0;
    let berverage = 0;
    let boiler = 0;
    let compliance = 0;
    let compressed = 0;
    let continuous = 0;
    let dg = 0;
    let effluent = 0;
    let environment = 0;
    let food_chemical = 0;
    let food_founder = 0;
    let food_micro = 0;
    let food_test = 0;
    let fuel_test = 0;
    let furnace = 0;
    let hourly = 0;
    let Incinerator = 0;
    let indoor = 0;
    let light = 0;
    let logistics = 0;
    let migration = 0;
    let monitoring = 0;
    let noise = 0;
    let nutrition_value = 0;
    let nutrition_label = 0;
    let other = 0;
    let others = 0;
    let shelf = 0;
    let soil = 0;
    let swab = 0;
    let training = 0;
    let water = 0;
    let work = 0;

    for (let i = 0; i < data?.length; i++) {
      air_corrosion_testing =
        Number(data[i]?.Air_Corrosion_Testing_collected) +
        Number(data[i]?.Air_Corrosion_Testing_planned) +
        Number(data[i]?.Air_Corrosion_Testing_scheduled);
      food_micro_test =
        Number(data[i]?.Food_Microbiology_Testing_collected) +
        Number(data[i]?.Food_Microbiology_Testing_planned) +
        Number(data[i]?.Food_Microbiology_Testing_scheduled);
      ambient_air_monitor =
        Number(data[i]?.Ambient_Air_Quality_Monitoring_8Hrs_collected) +
        Number(data[i]?.Ambient_Air_Quality_Monitoring_8Hrs_planned) +
        Number(data[i]?.Ambient_Air_Quality_Monitoring_8Hrs_scheduled);
      ambient_air_quality =
        Number(data[i]?.Ambient_Air_Quality_Testing_collected) +
        Number(data[i]?.Ambient_Air_Quality_Testing_planned) +
        Number(data[i]?.Ambient_Air_Quality_Testing_scheduled);
      ambient_noise =
        Number(data[i]?.Ambient_Noise_8_Hrs_collected) +
        Number(data[i]?.Ambient_Noise_8_Hrs_planned) +
        Number(data[i]?.Ambient_Noise_8_Hrs_scheduled);
        audit =
        Number(data[i]?.Audit_collected) +
        Number(data[i]?.Audit_scheduled) +
        Number(data[i]?.Audit_planned);
        beverage =
        Number(data[i]?.Beverages_Testing_collected) +
        Number(data[i]?.Beverages_Testing_planned) +
        Number(data[i]?.Beverages_Testing_scheduled);
        boiler =
        Number(data[i]?.Boiler_Stack_Testing_collected) +
        Number(data[i]?.Boiler_Stack_Testing_planned) +
        Number(data[i]?.Boiler_Stack_Testing_scheduled);
        compliance =
        Number(data[i]?.Compliance_collected) +
        Number(data[i]?.Compliance_planned) +
        Number(data[i]?.Compliance_scheduled);
        compressed =
        Number(data[i]?.Compressed_Air_Testing_collected) +
        Number(data[i]?.Compressed_Air_Testing_planned) +
        Number(data[i]?.Compressed_Air_Testing_scheduled);
        continuous =
        Number(data[i]?.Continuous_Ambient_Noise_24_hrs_Testing_collected) +
        Number(data[i]?.Continuous_Ambient_Noise_24_hrs_Testing_planned) +
        Number(data[i]?.Continuous_Ambient_Noise_24_hrs_Testing_scheduled);
        dg =
        Number(data[i]?.DG_stack_Testing_collected) +
        Number(data[i]?.DG_stack_Testing_planned) +
        Number(data[i]?.DG_stack_Testing_scheduled);
        effluent =
        Number(data[i]?.Effluent_Water_Testing_collected) +
        Number(data[i]?.Effluent_Water_Testing_planned) +
        Number(data[i]?.Effluent_Water_Testing_scheduled);
        environment =
        Number(data[i]?.Environment_Testing_collected) +
        Number(data[i]?.Environment_Testing_planned) +
        Number(data[i]?.Environment_Testing_scheduled);
        food_chemical =
        Number(data[i]?.Food_Chemical_Testing_collected) +
        Number(data[i]?.Food_Chemical_Testing_planned) +
        Number(data[i]?.Food_Chemical_Testing_scheduled);
        food_founder =
        Number(data[i]?.Food_Founders_Club_collected) +
        Number(data[i]?.Food_Founders_Club_planned) +
        Number(data[i]?.Food_Founders_Club_scheduled);
        food_micro =
        Number(data[i]?.Food_Microbiology_Testing_collected) +
        Number(data[i]?.Food_Microbiology_Testing_planned) +
        Number(data[i]?.Food_Microbiology_Testing_scheduled);
        food_test =
        Number(data[i]?.Food_Testing_collected) +
        Number(data[i]?.Food_Testing_planned) +
        Number(data[i]?.Food_Testing_scheduled);
        fuel_test =
        Number(data[i]?.Fuel_Testing_collected) +
        Number(data[i]?.Fuel_Testing_planned) +
        Number(data[i]?.Fuel_Testing_scheduled);
        furnace =
        Number(data[i]?.Furnace_Stack_Testing_collected) +
        Number(data[i]?.Furnace_Stack_Testing_planned) +
        Number(data[i]?.Furnace_Stack_Testing_scheduled);
        hourly =
        Number(data[i]?.Hourly_Ambient_Noise_24__Testing_collected) +
        Number(data[i]?.Hourly_Ambient_Noise_24__Testing_planned) +
        Number(data[i]?.Hourly_Ambient_Noise_24__Testing_scheduled);
        Incinerator =
        Number(data[i]?.Incinerator_Stack_Testing_collected) +
        Number(data[i]?.Incinerator_Stack_Testing_planned) +
        Number(data[i]?.Incinerator_Stack_Testing_scheduled);
        indoor =
        Number(data[i]?.Indoor_Air_Quality_Testing_collected) +
        Number(data[i]?.Indoor_Air_Quality_Testing_planned) +
        Number(data[i]?.Indoor_Air_Quality_Testing_scheduled);
        light =
        Number(data[i]?.Light_Intensity_Testing_collected) +
        Number(data[i]?.Light_Intensity_Testing_planned) +
        Number(data[i]?.Light_Intensity_Testing_scheduled);
        logistics =
        Number(data[i]?.Logistics_collected) +
        Number(data[i]?.Logistics_planned) +
        Number(data[i]?.Logistics_scheduled);
        migration =
        Number(data[i]?.Migration_Testing_collected) +
        Number(data[i]?.Migration_Testing_planned) +
        Number(data[i]?.Migration_Testing_scheduled);
        monitoring =
        Number(data[i]?.Monitoring_collected) +
        Number(data[i]?.Monitoring_planned) +
        Number(data[i]?.Monitoring_scheduled);
        noise =
        Number(data[i]?.Noise_Testing_collected) +
        Number(data[i]?.Noise_Testing_planned) +
        Number(data[i]?.Noise_Testing_scheduled);
        nutrition_value =
        Number(data[i]?.Nutrition_Value_Calculation_collected) +
        Number(data[i]?.Nutrition_Value_Calculation_planned) +
        Number(data[i]?.Nutrition_Value_Calculation_scheduled);
        nutrition_label =
        Number(data[i]?.Nutritional_Label_collected) +
        Number(data[i]?.Nutritional_Label_planned) +
        Number(data[i]?.Nutritional_Label_scheduled);
        other =
        Number(data[i]?.Other_collected) +
        Number(data[i]?.Other_planned) +
        Number(data[i]?.Other_scheduled);
        others =
        Number(data[i]?.Others_collected) +
        Number(data[i]?.Others_planned) +
        Number(data[i]?.Others_scheduled);
        shelf =
        Number(data[i]?.Shelf_Life_Testing_collected) +
        Number(data[i]?.Shelf_Life_Testing_planned) +
        Number(data[i]?.Shelf_Life_Testing_scheduled);
        soil =
        Number(data[i]?.Soil_Testing_collected) +
        Number(data[i]?.Soil_Testing_planned) +
        Number(data[i]?.Soil_Testing_planned);
        swab =
        Number(data[i]?.Swab_Testing_collected) +
        Number(data[i]?.Swab_Testing_planned) +
        Number(data[i]?.Swab_Testing_scheduled);
        training =
        Number(data[i]?.Training_collected) +
        Number(data[i]?.Training_planned) +
        Number(data[i]?.Training_scheduled);
        water =
        Number(data[i]?.Water_Testing_collected) +
        Number(data[i]?.Water_Testing_planned) +
        Number(data[i]?.Water_Testing_scheduled);
        work =
        Number(data[i]?.Work_Place_Monitoring_collected) +
        Number(data[i]?.Work_Place_Monitoring_planned) +
        Number(data[i]?.Work_Place_Monitoring_scheduled);
    }

    setAir_Corrosion_testing(air_corrosion_testing);
    setFood_Micro_Test(food_micro_test);
    setAmbient_air_monitor(ambient_air_monitor);
    setAmbient_air_quality(ambient_air_quality);
    setAmbient_noise(ambient_noise);
    setAudit(audit);
    setBeverage(berverage);
    setBoiler(boiler);
    setCompliance(compliance);
    setcompressed(compressed);
    setcontinuous(continuous);
    setdg(dg);
    seteffluent(effluent);
    setenvironment(environment);
    setfood_chemical(food_chemical);
    setfood_founder(food_founder);
    setfood_micro(food_micro);
    setFood_Test(food_test);
    setFuel_Test(fuel_test);
    setfurnace(furnace);
    sethourly(hourly);
    setIncinerator(Incinerator);
    setindoor(indoor);
    setlight(light);
    setlogistics(logistics);
    setmigration(migration);
    setnoise(noise);
    setnutrition_value(nutrition_value);
    setnutrition_label(nutrition_label);
    setother(other);
    setothers(others);
    setshelf(shelf);
    setsoil(soil);
    setswab(swab);
    settraining(training);
    setwater(water);
    setwork(work);
  }

  const get_service_heat_map = async (val) => {
    setHeat_Map_Load(true);
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    axios({
      url: `${base_url}/customer-service-heatmap`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setHeat_Map_Load(false);
        if (res?.data?.code == "200") {
          setService_Data(res?.data?.data[0]);
          setService_Header(res?.data?.data[1]);

// check_data(res?.data?.data[0])


        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setHeat_Map_Load(false);
        setService_Data([]);
        setService_Header([]);
      });
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_service_heat_map(params);
    }
  }, []);

  //   table parameters

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - service_data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  return (
    <>
      <Drawer_customer />
      <div
        className="container-fluid"
        style={{ paddingLeft: "5em", paddingRight: "1em" }}
      >
        <div className="row">
          <Paper
            sx={{
              // width: index == 0 ? "40%" : "20%",
              // width: "94%",
              mb: 2,
              padding: "0px",
              // marginLeft:"5%"
              // height: "500px",
            }}
          >
            <TableContainer id="order_tbl">
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    backgroundColor: "#007cc3",
                    color: "#444a4f !important",
                    position: "sticky",
                    top: 0,
                    zIndex: "999",
                  }}
                >
                  <TableRow>
                    <TableCell
                      id={styles.th_unit1}
                      style={{ borderBottom: "1px solid #89BBE0" }}
                      colspan={39}
                    >
                      <h5
                        className="ml-2 mt-2"
                        style={{
                          color: "white",
                          textAlign: "left",
                          marginLeft: "1%",
                        }}
                      >
                        {" "}
                        Services
                      </h5>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    {/* <TableCell
                  id={styles.th_unit1}
                  style={{ borderTop: "1px solid #89BBE0" }}
                >
                  {" "}
                </TableCell> */}
                    <TableCell id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        Site Name
                      </h6>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: air_corrosion_testing == 0 ? "none" : "block",
                      }}
                      id={styles.th_unit1}
                    >
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[0]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: ambient_air_monitor == 0 ? "none" : "block",
                      }}
                      id={styles.th_unit1}
                    >
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[1]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: ambient_air_quality == 0 ? "none" : "block",
                      }}
                      id={styles.th_unit1}
                    >
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[2]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                      sx={{ display: ambient_noise == 0 ? "none" : "block" }}
                      id={styles.th_unit1}
                    >
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[3]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                     sx={{display:audit == 0 ?"none":"block"}} 
                    id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[4]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: beverage == 0?"none":"block"}}
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[5]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: boiler == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[6]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: compliance == 0?"none":"block"}}
                      id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[7]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: compressed == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[8]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: continuous == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[9]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: dg == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[10]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: effluent == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[11]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    sx={{display: environment == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[12]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                     sx={{display: food_chemical == 0?"none":"block"}}
                       id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[13]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    // sx={{display: food_founder == 0?"none":"block"}} 
                     id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[14]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                      // sx={{ display: food_micro == 0 ? "none" : "block" }}
                      id={styles.th_unit1}
                    >
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[15]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    // sx={{display: food_test == 0?"none":"block"}}
                      id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[16]?.name}
                      </h6>
                    </TableCell>
                    <TableCell 
                    // sx={{display: fuel_test == 0?"none":"block"}}
                      id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[17]?.name}
                      </h6>
                    </TableCell>
                    <TableCell
                    //  sx={{display: furnace == 0?"none":"block"}} 
                      id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[18]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: Incinerator == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[19]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: indoor == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[20]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: light == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[21]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: logistics == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[22]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: migration == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[23]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: monitoring == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[24]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: noise == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[25]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: nutrition_value == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[26]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: nutrition_label == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[27]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: other == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[28]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: others == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[29]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: shelf == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[30]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: soil == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[31]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: swab == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[32]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: training == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[33]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: water == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[34]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: work == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[35]?.name}
                      </h6>
                    </TableCell>

                    {/* <TableCell sx={{display: beverage == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[36]?.name}
                      </h6>
                    </TableCell>
                    <TableCell sx={{display: beverage == 0?"none":"block"}}  id={styles.th_unit1}>
                      <h6 className="h6 mt-2" style={{ color: "white" }}>
                        {service_header[37]?.name}
                      </h6>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                {/* {data?.length == "0" ? (
              <TableBody>
                {" "}
                <TableRow>
                  <TableCell colSpan={11} className={styles.th_unit}>
                    <p style={{ textAlign: "center" }}>No Data Available</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody id={styles.body_content}>
                {(rowsPerPage > 0
                  ? data?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                )?.map((ele) => (
                  <TableRow>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2" style={{ textAlign: "left" }}>
                        {ele.sales_person}
                      </p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q1?.New)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q1?.Repeat)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q2?.New)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q2?.Repeat)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q3?.New)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total(ele?.Q3?.Repeat)}</p>
                    </TableCell>
                    <TableCell
                      className={styles.th_unit}
                      sx={{ width: "200px" }}
                    >
                      <p className="p mt-2">{get_total(ele?.Q4?.New)}</p>
                    </TableCell>
                    <TableCell
                      className={styles.th_unit}
                      sx={{ width: "200px" }}
                    >
                      <p className="p mt-2">{get_total(ele?.Q4?.Repeat)}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total_val(ele, "New")}</p>
                    </TableCell>
                    <TableCell className={styles.th_unit}>
                      <p className="p mt-2">{get_total_val(ele, "Repeat")}</p>
                    </TableCell>
                 
                    
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )} */}
              </Table>
            </TableContainer>
            <TablePagination
              id={styles.footer_div}
              class="footer_div"
              sx={{ backgroundColor: "#007cc3", color: "white" }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={service_data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}

export default Services_avails;
