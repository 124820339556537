import * as React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
  gaugeClasses ,
  Gauge
} from '@mui/x-charts/Gauge';
import { Typography } from '@mui/material';
import styles from "../Styles/Leads.module.css"

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="var(--Red, #EB5757)" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="var(--Red, #EB5757)"
        strokeWidth={3}
      />
    </g>
  );
}

export default function CompositionExample({data}) {
  return (
    <>
    <Gauge
      width={200}
      height={200}
      startAngle={-90}
      endAngle={90}
      value={data?.TOTAL_ACHIEVEMENT_COUNT}
      valueMax={data?.TOTAL_TAGET_COUNT}
      // cx={80}
      cx={115}
      cy={70}

      sx={{
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 14,
          transform: 'translate(0px, 20px)',
        },
      }}
      text={
         ({ value, valueMax }) => `${value} / ${valueMax} ` 
      }
    >

      
      <GaugeReferenceArc />
      <GaugeValueArc 

      />
      <GaugePointer />
  
    </Gauge>
<p id={styles.gauge_text}>Target Vs Achievement</p>
     {/* <Typography sx={{marginTop:"-110px",marginLeft:"45%"}}>{data?.TOTAL_ACHIEVEMENT_COUNT}</Typography> */}
     </>
  );
}