import React from "react";
import styles from "../Styles/Leads.module.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DatePicker, Space } from 'antd';

function Yearly_audit_tbl({ data ,handleYear,audit_year}) {
  return (
    <div id="yearly_tbl">
       <div id={styles.date_container}><DatePicker onChange={handleYear} value={audit_year} picker="year" /> </div>
      {/* <Paper
        sx={{
          mb: 2,
          padding: "0px",
          // marginLeft: "4%",
          marginTop: "1%",
          width: "100%",
        }}
        id={styles.table_container}
      > */}
        <TableContainer style={{borderRadius:"3px",mb: 2,
          padding: "0px",
          // marginLeft: "4%",
          marginTop: "1%",
          width: "100%"}} id={styles.audit_tbl}>
          <Table stickyHeader aria-label="sticky table">

            <TableHead
              sx={{
                backgroundColor: "#007cc3",
                color: "#444a4f !important",
                position: "sticky",
                top: 0,
                zIndex: "999",
              }}
            >
            
              <TableRow>
                <TableCell sx={{borderBottom:"1px solid white"}} colSpan={2}></TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Jan</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Feb</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Mar</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Apr</h6>
                </TableCell>
                <TableCell id={styles.header_unit}>
                  <h6 className="h6 mt-2">May</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Jun</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Jul</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Aug</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Sep</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Oct</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Nov</h6>
                </TableCell>
                <TableCell id={styles.header_unit} >
                  <h6 className="h6 mt-2">Dec</h6>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <h5 id={styles.lead_header}>
                    Audits
                  </h5>
                </TableCell>
                {data?.map((ele) => (
                  <TableCell id={styles.count_container}>
                    <p>{ele?.TOTAL_AUDITS_COUNT}</p>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow  rowSpan={3}>
                <TableCell>
                  <h5 id={styles.lead_header} >SOA</h5>
                </TableCell>
                <TableCell id={styles.status_container}>
                <div>
                  <TableRow sx={{ display: "flex" }}>
                
                    <TableCell>
                 
                      <p style={{ textAlign: "left" }}>New</p>
                 
                    </TableCell>
                   
                  </TableRow>
                  </div>
                  <div>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell>
                      <p style={{ textAlign: "left" }}>Scheduled</p>
                    </TableCell>
                  </TableRow>
                  </div>
                  <div>
                  <TableRow sx={{ display: "flex", justifyContent: "left" }}>
                    <TableCell sx={{ width: "100%" }}>
                      <p style={{ textAlign: "left" }}>Audit Done</p>
                    </TableCell>
                  </TableRow>
                  </div>
                </TableCell>
                {data?.map((ele) => (
                  <TableCell id={styles.count_container}>
                    <TableRow
                     
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p>{ele?.TOTAL_SOA_NEW_COUNT}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p>{ele?.TOTAL_SOA_SCHEDULED_COUNT}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p id={styles.audit_done_val} >{ele?.TOTAL_SOA_AUDIT_DONE_COUNT}</p>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow rowSpan={3}>
                <TableCell>
                  <h5  id={styles.lead_header}>Upfront</h5>
                </TableCell>
                <TableCell id={styles.status_container}>
                <div>
                  <TableRow sx={{ display: "flex" }}>
                
                    <TableCell >
                 
                      <p style={{ textAlign: "left" }}>New</p>
                 
                    </TableCell>
                   
                  </TableRow>
                  </div>
                  <div>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell>
                      <p style={{ textAlign: "left" }}>Scheduled</p>
                    </TableCell>
                  </TableRow>
                  </div>
                  <div>
                  <TableRow sx={{ display: "flex", justifyContent: "left" }}>
                    <TableCell sx={{ width: "100%" }}>
                      <p style={{ textAlign: "left" }}>Audit Done</p>
                    </TableCell>
                  </TableRow>
                  </div>
                </TableCell>
                {data?.map((ele) => (
                  <TableCell id={styles.count_container}>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p>{ele?.TOTAL_UPFRONT_NEW_COUNT}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p>{ele?.TOTAL_UPFRONT_SCHEDULED_COUNT}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p id={styles.audit_done_val} >{ele?.TOTAL_UPFRONT_AUDIT_DONE_COUNT}</p>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow rowSpan={3}>
                <TableCell sx={{width:"100px"}}>
                  <h5 id={styles.lead_header}>Free</h5>
                </TableCell>
                <TableCell id={styles.status_container}>
                <div>
                  <TableRow sx={{ display: "flex" }}>
                
                    <TableCell >
                 
                      <p style={{ textAlign: "left" }}>New</p>
                 
                    </TableCell>
                   
                  </TableRow>
                  </div>
                  <div>
                  <TableRow sx={{ display: "flex" }}>
                    <TableCell>
                      <p style={{ textAlign: "left" }}>Scheduled</p>
                    </TableCell>
                  </TableRow>
                  </div>
                  <div>
                  <TableRow sx={{ display: "flex", justifyContent: "left" }}>
                    <TableCell sx={{ width: "100%" }}>
                      <p style={{ textAlign: "left" }}>Audit Done</p>
                    </TableCell>
                  </TableRow>
                  </div>
                </TableCell>
                {data?.map((ele) => (
                  <TableCell id={styles.count_container}>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p>{ele?.TOTAL_FREE_NEW_COUNT}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p>{ele?.TOTAL_FREE_SCHEDULED_COUNT}</p>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <TableCell>
                        <p id={styles.audit_done_val}>{ele?.TOTAL_FREE_AUDIT_DONE_COUNT}</p>
                      </TableCell>
                    </TableRow>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
            {/* {service_data?.length == "0" ? (
      <TableBody>
        <TableRow>
          <TableCell id={styles.td_unit} colSpan={11}>
            <p style={{ textAlign: "center", padding: "0px" }}>
              No Data Available
            </p>
          </TableCell>
    
        </TableRow>
      </TableBody>
    ) : (
      <TableBody>
      {(rowsPerPage > 0
                  ? service_data?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : service_data
                )?.map((ele)=>(
       <TableRow sx={{border:"1px solid rgb(201, 201, 201)"}}>
         <TableCell  style={{color:'black',minWidth:'150px' ,border:"1px solid rgb(201, 201, 201)"}}>{ele?.site_name}</TableCell>

         {ele?.service_data?.map((el)=>(

           <>
            <TableCell  sx={{border:"1px solid rgb(201, 201, 201)"}}>{el?.planned}</TableCell> 
            <TableCell sx={{border:"1px solid rgb(201, 201, 201)"}}>{el?.scheduled}</TableCell> 
            <TableCell sx={{border:"1px solid rgb(201, 201, 201)"}}>{el?.collected}</TableCell> 

             </>

         ))}

       </TableRow>
      ))}
      </TableBody>
    )} */}
          </Table>
        </TableContainer>
        {/* <TablePagination
id={styles.footer_div}
class="footer_div"
sx={{ backgroundColor: "#007cc3", color: "white" }}
rowsPerPageOptions={[10, 25, 50, 100]}
component="div"
count={service_data?.length}
rowsPerPage={rowsPerPage}
page={page}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
/> */}


      {/* </Paper> */}

    </div>
  );
}

export default Yearly_audit_tbl;
