import React, { Component, useState } from "react";
import styles from "../Styles/Table.module.css";
import MUIDataTable from "mui-datatables";
import { saveAs } from "file-saver";
import moment from "moment";
import axios from "axios";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import Swal from "sweetalert2";

// import { createTheme, ThemeProvider } from '@mui/material/styles';

// const theme = createTheme({
//   components:{
//     MUIDataTableFooter:{
//       styleOverrides:{
//         root:{
//           zIndex:1,
//         }
//       }
//     }
//   }
// });

function Collection_tbl({ data, setFile_Path, selected_flag, count_flag ,setRow_Status}) {
  const storage = window.sessionStorage;
  // const classes = useStyles();

  const [staus_flag,setStatus_Flag] = useState(null)


  const handleClick = (row,data) => {
    //   (async () => {
    //     let blob = await fetch(row).then((r) => r.blob());
    //     saveAs(blob);
    //  })();
    // saveAs(row);

    // axios({
    //   url: row,
    //   method: "GET",
    //   mode: "no-cors",
    // })
    //   .then((res) => {
    //     res.blob();
    //   })
    //   .then((blob) => {
    //     saveAs(blob);
    //   })
    //   .catch((err) => {
    //     
    //   });

    //  let pdf_url = row;
    //  fetch(pdf_url)
    //  .then(response=>response.blob())
    //  .then(blob=>{
    //   saveAs(blob)
    //  })
    //  .catch(error=>{
    //   
    //  })
//  if(data?.rowData[1] == 1){
  saveAs(row);
//  }
 
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [hover_flag, setHover_Flag] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [file_list, setFile_list] = useState([]);

  const handleRow = (data_row) => {

    // if (!hover_flag && data_row[1] == "1") {
       if(!hover_flag) {    
      setRow_Status(data_row[1])
        setFile_Path(data_row[0][0]?.url);
       }

    // }
  };

  function handleClose() {
    setAnchorEl(null);
    setHover_Flag(false)
  }

  const handleView = (row, data, e) => {
    e.stopPropagation();
    setFile_list(row);
    setAnchorEl(e.currentTarget);
    setHover_Flag(true)
    setStatus_Flag(data?.rowData[1])
  };

  const columns = [
    {
      name: "reports_files",
      label: "reports_files",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "payment_status",
      label: "payment_status",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "date_of_collection",
      label: "Date Of Collection",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "description",
      label: "Sample Description",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "source_of_sample",
      label: "Source",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "10px" }}>{row}</p>;
        },
      },
    },
    {
      name: "date_of_report",
      label: "Date Of Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "report_sent_date",
      label: "Report Sent Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          if (row == null || row == "") return <></>;
          return (
            <p style={{ textAlign: "center" }}>
              {moment(row).format("DD MMM YYYY")}
            </p>
          );
        },
      },
    },
    {
      name: "reports_files",
      label: "reports files",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row,data) => {
  
          return (
            <>
              <div
              
                //  onMouseLeave={handleClose}
              >
                <p id={styles.view_btn} onMouseEnter={(e) => handleView(row, data, e)}>View</p>

              </div>

        {/* <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {file_list?.map((ele, index) => (

    <MenuItem onClick={() => handleClick(ele?.url,data)}>
            <span>file {index + 1}</span>
            <img
              src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
              style={{
                height: "20px",
                marginLeft: "10%",
                cursor: "pointer",
                // opacity: data?.rowData[1] == 1?"1":"0.3"
              }}
              onClick={() => handleClick(ele?.url,data)}
            />
          </MenuItem>
          
        ))}
      </Menu> */}

          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                MenuListProps={{ onMouseLeave: handleClose }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {file_list?.map((ele, index) => (
  
                  <div>
           
                 {staus_flag != 1?  <MenuItem >
                    <span>file {index+1}</span>
      
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                      style={{
                        height: "20px",
                        marginLeft: "10%",
                        cursor: "pointer",
                        opacity:"0.3"
                      }}
                    />
                  </MenuItem>:
                 <MenuItem onClick={() => handleClick(ele?.url)}>
                 <span>file {index+1}</span>
                 
                 <img
                   src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                   style={{
                     height: "20px",
                     marginLeft: "10%",
                     cursor: "pointer",
                   }}
                   onClick={() => handleClick(ele?.url)}
                 />
               </MenuItem>
                 
                  }
                   </div>
                ))}
              </Menu>
        
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };

  return (
    <div id="collection_tbl">
      {/* <ThemeProvider theme={theme}> */}
      <MUIDataTable
        // title={
        //   <h4 id={styles.list_head}>
        //     Collection Table
        //   </h4>
        // }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: false,
          filter: false,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,
          onRowClick: (rowData) => {
    
            // if(!hover_flag){
              handleRow(rowData);
            // }

          },

          sortOrder: {
            name: "date_of_collection",
            direction: "desc",
          },
          setRowProps: () => ({
            style: {
              cursor: "pointer",
            },
          }),
          textLabels: {
            body: {
              noMatch:
                selected_flag == "" && count_flag
                  ? "Please Select tab to get Report List"
                  : "Data Not Available",
            },
          },
        }}
      />

      {/* <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem >Organisation Details</MenuItem>

            <MenuItem >Switch Account</MenuItem>
            <MenuItem
              sx={{ justifyContent: "center", color: "#007cc3" }}
          
            >
              Signout
            </MenuItem>
          </Menu> */}

      {/* </ThemeProvider> */}
    </div>
  );
}

export default Collection_tbl;











      {/* <div className={styles.dropdown}>
                <span style={{ color: "#007cc3" }}>View</span>
                <div   onMouseEnter={() => setHover_Flag(true)}
                     onMouseLeave={() => setHover_Flag(false)} className={styles.dropdown_content}>
                  {row?.map((ele, index) => (
                    <div

                      onClick={() => handleClick(ele?.url)}
                    >
                      <span>file{index + 1}</span>
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                        style={{
                          height: "20px",
                          marginLeft: "10%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClick(ele?.url)}
                      />
                    </div>
                  ))}
                </div>
              </div> */}