import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import styles from "../Styles/Modals.module.css"


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  borderRadius:"20px",
  boxShadow: 24,
  p: 4,
};
function Edit_Site({open,handleOpen,handleClose,individual_data}) {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container-fluid" id={styles.modal_container}>
                <div className="row" style={{display:"flex",flexDirection:"column"}}>
                     <h5 style={{marginLeft:"0px",fontWeight:"bold"}}>Edit Site Details</h5>
           
                </div>
              <div id={styles.modal_body}>
               <div className='row'>
                  <div className="col-sm-12 col-md-4">
                  <label>Site Name: </label>
                    <input type="text" className="form-control" defaultValue={individual_data[0]} />
                  </div>
                  <div className="col-sm-12 col-md-4">
                  <label>Contact Person: </label>
                    <input type="text" className="form-control" defaultValue={individual_data[4]} />
                  </div>
                  <div className="col-sm-12 col-md-4">
                  <label>Contact Number: </label>
                    <input type="text" className="form-control" defaultValue={individual_data[6]} />
                  </div>
                
                </div>          
                <div  className='row mt-2'>
                   <div  className="col-sm-12 col-md-6"> 
                   <label>State:</label>
                   <input type="text" className="form-control" defaultValue={individual_data[1]} />
                   </div>
                   <div  className="col-sm-12 col-md-6"> 
                   <label>City:</label>
                   <input type="text" className="form-control" defaultValue={individual_data[2]} />
                   </div>
         
                </div>

                <div  className='row mt-2' style={{display:"flex",alignItems:"center"}}>
                <div  className="col-sm-12 col-md-12"> 
                   <label>Site Address:</label>
                   <textarea name="" id="" cols="30" rows="auto"className="form-control" defaultValue={individual_data[3]} style={{height:"80px",resize:"none"}}></textarea>
                   </div>
                  {/* <div className="col-sm-12 col-md-6">
                  <label>Bill To Address:</label>
                   <textarea name="" id="" cols="30" rows="auto"className="form-control" defaultValue={individual_data[0]} style={{height:"80px",resize:"none"}}></textarea>
                  </div>                 */}
                </div>
              
                <div  className='row mt-2'>
                   <div className="col-sm-12 col-md-6">
                  <label>Bill To GST No:</label>
                  <input type="text" className="form-control" defaultValue={individual_data[0]} />
                  </div>
                   <div className="col-sm-12 col-md-6">
                  <label>ship to gst no:</label>
                  <input type="text" className="form-control" defaultValue={individual_data[0]} />
                  </div>
                </div>
    
                <div className='row mt-2' style={{display:"flex",justifyContent:"end",gap:"20px"}}>
                <button className="btn btn-primary col-sm-5 col-md-2" id={styles.modal_btn}>EDIT</button>
                <button className="btn btn-primary col-sm-5 col-md-2" id={styles.modal_btn2} onClick={handleClose}>Close</button>
                </div> 

                </div>

                </div>
        </Box>
      </Modal>
    </div>
  )
}

export default Edit_Site