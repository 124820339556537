import React, { useEffect, useState } from 'react'
import Drawer_customer from '../Components/Drawer'
import Swal from "sweetalert2";
import axios from "axios";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import styles from "../Styles/Services.module.css";
import Schedule_tbl from '../Components/Schedule_tbl';
import Serviceplan_site from '../Components/Serviceplan_site';
import loader_img from "../Media/Business_chart.gif"
import Footer from '../Components/footer';

const { RangePicker } = DatePicker;

function Site_Page({base_url}) {
  const storage = window.sessionStorage;
  let profile = JSON.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  let customer_name = storage.getItem("customer_name");
  let site_name = storage.getItem("site_name");
  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");


  
  const [params, setParams] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [send_from_date, setSend_from_Date] = useState("");
  const [send_to_date, setSend_to_Date] = useState("");
  const [displayDate1, setDisplayDate1] = useState(false);
  const [date_show, setDate_Show] = useState(false);
  const [service_data,setService_Data] = useState([])

    // schedule useStates

    const [schedule_params,setSchedule_Params] = useState("")
    const [range_flag, setRange_Flag] = useState(false);
    const [selectedFromDate_schedule, setSelectedFromDate_Schedule] = useState();
    const [selectedToDate_schedule, setSelectedToDate_Schedule] = useState();
    const [start_date, setStart_Date] = useState(new Date());
    const [end_date, setEnd_Date] = useState(new Date());
    const [display2,setDisplay2] = useState(false)
    const [schedule_list, setSchedule_List] = useState([]);


    // load useStates

    const [schedule_load,setSchedule_Load] = useState(false)
    const [heat_map_load,setHeat_Map_Load] = useState(false)

    const get_service_heat_map = async(temp_param) =>{
      setHeat_Map_Load(true)
      let token = storage.getItem("token");
  axios.defaults.headers.common = {
    Authorization: "Bearer " + token?.replace('"', ""),
  };
  const formData = new FormData();

  formData.append("customer_id", customer_id);
  if(site_id!="" && site_id!= null){
    formData.append("site_id", site_id);
    } 
  if(temp_param != "custom"){
    formData.append("collected_date", temp_param);
  }
  if(temp_param == "custom"){
  formData.append("start_date", send_from_date);
  formData.append("end_date", send_to_date);
  }
  formData.append("year", info_arr[3]);
  axios({
    url: `${base_url}/customers-site-service-heatmap`,
    method: "POST",
    data: formData,
  })
    .then((res) => {
      setHeat_Map_Load(false)
      if (res?.data?.code == "200") {
        setService_Data(res?.data?.data)
        // setSchedule_List(res?.data?.data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: res?.data?.message,
        });
      }
    })
    .catch((err) => {
      setHeat_Map_Load(false)
      
    });
    } 



  const handleParameter = (e) => {
    setParams(e.target.value);
    if (e.target.value == "custom") {
      setDate_Show(true);
    } else {
      setDate_Show(false);
      get_service_heat_map(e.target.value);
    }
  };
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedFromDate(dayjs(dates[0]?.$d));
      setSelectedToDate(dayjs(dates[1]?.$d));
      setSend_from_Date(moment(dates[0].$d).format("YYYY-MM-DD"));
      setSend_to_Date(moment(dates[1].$d).format("YYYY-MM-DD"));
      setDisplayDate1(true);
    } else {
      setSelectedFromDate(null);
      setSelectedToDate(null);
      setSend_from_Date("");
      setSend_to_Date("");
      setDisplayDate1(false);
      setDate_Show(false);
      setParams("");
      get_service_heat_map("");
    }
  };

  const handle_date_change = (e) => {
    if(selectedFromDate == undefined && selectedToDate == undefined){
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Date",
      });
    }else{
      get_service_heat_map(params);
      setDisplayDate1(false);
      }

  };



// schedule functions



const get_schedule_list = (temp_param) => {
  setSchedule_Load(true)
  let token = storage.getItem("token");
  axios.defaults.headers.common = {
    Authorization: "Bearer " + token?.replace('"', ""),
  };
  const formData = new FormData();

  formData.append("customer_id", customer_id);
  if(site_id!="" && site_id!= null){
    formData.append("site_id", site_id);
    } 
  if(temp_param != "custom"){
    formData.append("scheduled_date", temp_param);
  }
  if(temp_param == "custom"){
  formData.append("start_date", start_date);
  formData.append("end_date", end_date);
  }

  axios({
    url: `${base_url}/customer-dashboard-scheduled-list`,
    method: "POST",
    data: formData,
  })
    .then((res) => {
      setSchedule_Load(false)
      if (res?.data?.code == "200") {
        setSchedule_List(res?.data?.data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: res?.data?.message,
        });
      }
    })
    .catch((err) => {
      setSchedule_Load(false)
      
    });
};


  const handleDateRange_schedule = (e) => {
    setSchedule_Params(e.target.value);
    if (e.target.value == "custom") {
      setRange_Flag(true);
    } else {
      setRange_Flag(false);
      setSelectedFromDate_Schedule();
      setSelectedToDate_Schedule();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_schedule_list(e.target.value)
    }
  };


  const handleDateChange_schedule = (new_val)=>{
    if (new_val !== null) {
      setSelectedFromDate_Schedule(new_val[0]);
      setSelectedToDate_Schedule(new_val[1]);
      let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      setStart_Date(newStartDate);
      setEnd_Date(newEndDate);
      setDisplay2(true)
      // setShowBtn(true);
    }else{
      setSchedule_Params("")
      setRange_Flag(false)
      setSelectedFromDate_Schedule();
      setSelectedToDate_Schedule();
      setStart_Date(new Date());
      setEnd_Date(new Date());
      get_schedule_list("")
      setDisplay2(false)
    }
  }

  const handle_click_schedule = () => {
    if(selectedFromDate_schedule == undefined && selectedToDate_schedule == undefined){
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please Select Date",
      });
    }else{
      get_schedule_list(schedule_params)
 
      }
 
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_service_heat_map(params);
      get_schedule_list(schedule_params);
    }
  }, []);


  return (
    <>
    <Drawer_customer/>
    {(heat_map_load || schedule_load)?<img src={loader_img} id={styles.loader_schedule}  />:  <div className='container-fluid' 
     style={{ paddingLeft: "5em", paddingRight: "1em" }}
     >
        <div className="row" style={{display:"flex",justifyContent:"end"}}>
          {/* <p style={{textAlign:"right",marginRight:"20px"}}><CalendarMonthIcon/></p>  */}
          <div className={date_show?'col-7':'col-10'}>
          <p id={styles.site_name_head}>{site_name}</p>
          </div>
    
          <div className="col-2">
            <select
              id={styles.selectstyle}
              value={params}
              className="form-select"
              name=""
              // id=""
              onChange={handleParameter}
            >
       <option value="" selected > 
                      All
                    </option>
       {/* <option value="" selected disabled> 
                      Please Select
                    </option> */}
              <option value="today">Today</option>
              <option value="month_till_date">Month Till Date</option>
              <option value="quarter_till_date">Quarter Till Date</option>
              <option value="last_quarter">Last Quarter</option>
              <option value="last_six_month">Last Six Month</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          {date_show ? (
            <div
              className="col-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Space direction="vertical" size={12}>
                <RangePicker
                  format="DD-MM-YYYY"
                  value={[selectedFromDate, selectedToDate]}
                  onChange={onRangeChange}
                />
              </Space>
              <button
                className="btn btn-primary btn-sm"
                style={{
                  display: displayDate1 ? "flex" : "none",
                  marginLeft: "10px",
                }}
                onClick={handle_date_change}
              >
                Apply
              </button>
            </div>
          ) : (
            <></>
          )}

        </div>

<div className='row'  id={styles.heat_map}>
  
<Serviceplan_site service_data={service_data}/>
</div>

<div className="row mt-5 mb-4">
  <Schedule_tbl 
                        handleDateRange={handleDateRange_schedule}
                        range_flag={range_flag}
                        selectedFromDate={selectedFromDate_schedule}
                        selectedToDate={selectedToDate_schedule}
                        handleDateChange = {handleDateChange_schedule} 
                        data={schedule_list}
                        handleClick={handle_click_schedule}
                        display2={display2}
                        params_schedule={schedule_params}
    />
</div>

    </div>}
{/* 
    <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
          
                    <p id={styles.copyright}>
     

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
     
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}
          <Footer/>
    </>
  )
}

export default Site_Page