import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Typography, Box, Divider } from '@mui/material';

function Site_record_line_chart({data}) {
  const CustomTooltip = ({ active, payload, label }) => {

    if (active && payload[0]?.payload?.MONTH!="") {
      return (
        <Box sx={{ background: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="subtitle1">{`${payload[0]?.payload?.MONTH}`}</Typography>
          <Typography sx={{color:"#007cc3"}} variant="body1">{`Percentage: ${payload[0]?.value}%`}</Typography>
        </Box>
      );
    }
  
    return null;
  };
  
  return (
    <div>
         <LineChart
          width={data?.length<4?500:1200}
          height={420}
          data={data}
          margin={{
            top:  40,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis     
            // label={{
            //           value: `Month`,
            //           style: { textAnchor: "middle" },
            //           angle: 0,
            //           position: "center",
            //           offset: 0,
            //         }} 
                    dataKey="MONTH" />
          <YAxis
           label={{
            value: `Percentage`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          />
          {/* <Tooltip /> */}
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Line  type='monotone' dataKey="PERCENTAGE" stroke="#8884d8"  label={{ position: "top" }} activeDot={{ r: 8 }}  />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
    </div>
  )
}

export default Site_record_line_chart