import React from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import styles from "../Styles/Audit.module.css"

function Upcoming_schedule_tbl({data}) {
    const columns = [
        {
          name: "AUDIT_START_DATE",
          label: "Promise Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "" || row == undefined) return <>--</>;
              return (
                <span style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </span>
              );
            },
          },
        },
        // {
        //   name: "AUDIT_DONE_DATE",
        //   label: "Done Date",
        //   options: {
        //     filter: false,
        //     sort: false,
        //     customBodyRender: (row, data) => {
        //       if (row == null || row == "" || row == undefined) return <>--</>;
        //       return (
        //         <span style={{ textAlign: "center" }}>
        //           {moment(row).format("DD MMM YYYY")}
        //         </span>
        //       );
        //     },
        //   },
        // },
    {
      name: "RES_ID",
      label: "Res Id",
      options: {
        filter: false,
        sort: false,
        // display: false,
        download: false,
      },
    },
    {
      name: "Site_Name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (row, data) => {
        //   if (row == null || row == "") return <></>;
        //   return (
        //     <p style={{ textAlign: "center" }}>
        //       {moment(row).format("DD MMM YYYY")}
        //     </p>
        //   );
        // },
      },
    },
    {
      name: "CURRENT_STATE_ID",
      label: "State",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (row, data) => {
        //   if (row == null || row == "") return <></>;
        //   return (
        //     <p style={{ textAlign: "center" }}>
        //       {moment(row).format("DD MMM YYYY")}
        //     </p>
        //   );
        // },
      },
    },
    {
      name: "CURRENT_CITY_ID",
      label: "City",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (row, data) => {
        //   if (row == null || row == "") return <></>;
        //   return (
        //     <p style={{ textAlign: "center" }}>
        //       {moment(row).format("DD MMM YYYY")}
        //     </p>
        //   );
        // },
      },
    },

    // {
    //   name: "Client",
    //   label: "Project Name",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "Audit_Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (row) => {
          return <p style={{ padding: "10px"}}>{row}</p>;
        },
      },
    },

   
  ];

  const options = {
    filterType: "checkbox",
  };
  return (
    <div>
         <MUIDataTable
        title={
          <h4 id={styles.list_head}>
           Upcoming Schedules
          </h4>
        }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: true,
          filter: true,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,
          // onRowClick: (rowData) => {
          //   handleRow(rowData);
          // },

          // sortOrder: {
          //   name: "date_of_collection",
          //   direction: "desc",
          // },
        
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
        }}
      />
    </div>
  )
}

export default Upcoming_schedule_tbl