// import { BarChart  } from '@mui/x-charts'
import React,{useRef} from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Typography, Box, Divider } from '@mui/material';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    
  if (active) {
    return (
      <Box sx={{ background: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
        <Typography variant="subtitle1">{` ${payload[0]?.payload?.NAME}`}</Typography>
        <Typography  sx={{color:"#007cc3"}} variant="body1">{`Percentage: ${payload[0]?.value}%`}</Typography>
      </Box>
    );
  }

  return null;
};

function Seven_days_graph({xlabel,data,graph_data}) {
  const windowHeight = useRef(window.innerHeight);
  // const columns = [
  //   {
  //     name: "ID",
  //     label: "ID",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       // display: false,
  //       download: false,
  //     },
  //   },
  //   {
  //     name: "SITE_NAME",
  //     label: "Site Name",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       customBodyRender: (row, data) => {
  //         if (row == null || row == "") return <></>;
  //         return (
  //           <p style={{ textAlign: "center" }}>
  //             {moment(row).format("DD MMM YYYY")}
  //           </p>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "PROJECT_NAME",
  //     label: "Project Name",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "SUDIT_STATUS",
  //     label: "Audit Status",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row) => {
  //         return <p style={{ padding: "10px" }}>{row}</p>;
  //       },
  //     },
  //   },
  //   {
  //     name: "DATE",
  //     label: "Date",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (row, data) => {
  //         if (row == null || row == "") return <></>;
  //         return (
  //           <p style={{ textAlign: "center" }}>
  //             {moment(row).format("DD MMM YYYY")}
  //           </p>
  //         );
  //       },
  //     },
  //   },
  // ];

  // const options = {
  //   filterType: "checkbox",
  // };



  return (
    <>
    <div>
        {data?.length>0?
      //     <div>
      //         <MUIDataTable
      //   // title={
      //   //   <h4 id={styles.list_head}>
      //   //     Collection Table
      //   //   </h4>
      //   // }
      //   data={sevenDayData}
      //   columns={columns}
      //   options={{
      //     options: options,
      //     selectableRows: "none",
      //     responsive: "standard",
      //     viewColumns: false,
      //     print: false,
      //     download: false,
      //     search: false,
      //     filter: false,
      //     // pagination: false,
      //     rowsPerPageOptions: [10, 25, 50, 100],
      //     tableBodyMaxHeight: "40vh",
      //     scroll: false,
      //     // onRowClick: (rowData) => {
      //     //   handleRow(rowData);
      //     // },

      //     // sortOrder: {
      //     //   name: "date_of_collection",
      //     //   direction: "desc",
      //     // },
      //     setRowProps: () => ({
      //       style: {
      //         cursor: "pointer",
      //       },
      //     }),
      //     textLabels: {
      //       body: {
      //         noMatch: "Data Not Available",
      //       },
      //     },
      //   }}
      // />
      //     </div>
<>
        <BarChart
          width={graph_data?.length<4?500:900}
          height={350}
          data={graph_data}
        
          margin={{
            top:  20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            // label={{
            //           value: `Month`,
            //           style: { textAnchor: "middle" },
            //           angle: 0,
            //           position: "center",
            //           offset: 100,
            //         }} 
          dataKey="AUDIT_END_ON" />
          <YAxis 
           label={{
            value: `Percentage`,
            style: { textAnchor: "middle" },
            angle: -90,
            position: "left",
            offset: 0,
          }}
          />
          <Tooltip content={<CustomTooltip />} />

          <Bar dataKey="PERCENTAGE" fill="#007cc3" barSize={60}  label={{ position: "top" }} />
          {/* <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
        </BarChart>

</>
//         <BarChart
//         dataset={graph_data}
//       xAxis={[{ scaleType: 'band', data: xlabel, categoryGapRatio: data?.length<5?0.7:0.3,
//       barGapRatio: 0.2}]}
//       series={[{data:data,valueFormatter: (value) => value==null?value:`${value}%`,
//       color:"#007cc3",


   
//     }]}
  
//       width={data?.length<4?500:900}
//       // width={(windowHeight.current<760&&windowHeight.current>=740)?600:1000}
//       height={400}
//       barLabel="value"
// //       barLabel={(item, context) => {

// //    if(item?.value == null)return<></>
// //    else return <span>{item?.value}</span>
// //       }}
//     />

    :
    <></> 
    }   
    </div>
    </>
  )
}

export default Seven_days_graph