import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Drawer_customer from "../Components/Drawer";
import styles from "../Styles/Service_avails.module.css";
import loader_img from "../Media/Business_chart.gif";
// import no_data from "../Media/no_data_icon.gif"
import no_data from "../Media/no_data_icon.jpg";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../Components/footer";

function Service_on_customer({ base_url }) {
  const storage = window.sessionStorage;
  let profile = JSON.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let site_id = storage.getItem("site_id");
  let token = storage.getItem("token");
  let customer_name = storage.getItem("customer_name");
  const [params, setParams] = useState("");

  let support_details = JSON?.parse(storage.getItem("support_details"));
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const [service_data, setService_Data] = useState([]);
  const [service_header, setService_Header] = useState([]);
  const [heat_map_load, setHeat_Map_Load] = useState(false);

  const [total_count, setTotal_Count] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - service_data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const get_service_data = async (val) => {
    setHeat_Map_Load(true);
    let token = storage.getItem("token");
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();

    formData.append("customer_id", customer_id);
    axios({
      url: `${base_url}/customer-service-heatmap`,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setHeat_Map_Load(false);
        if (res?.data?.code == "200") {
          // setService_Data(res?.data?.data[0]);
          // setService_Header(res?.data?.data[1]);
          // setTotal_Count(res?.data?.data[2]);
          setService_Data(res?.data?.data);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        setHeat_Map_Load(false);
        setService_Data([]);
        setService_Header([]);
      });
  };

  useEffect(() => {
    if (token != "" && token != null) {
      get_service_data();
    }
  }, []);


  // let data = [
  //   {
  //     site_name:"bangalore",
  //     service_data:[
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },

  //     ]
  //   },
  //   {
  //     site_name:"Mumbai",
  //     service_data:[
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },

  //     ]
  //   },
  //   {
  //     site_name:"Panvel",
  //     service_data:[
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },

  //     ]
  //   },
  //   {
  //     site_name:"Thane",
  //     service_data:[
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },

  //     ]
  //   },
  //   {
  //     site_name:"Goa",
  //     service_data:[
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },
  //       {
  //         "service_name":"water testing",
  //         "planned":"0",
  //         "scheduled":"2",
  //         "collected":"1"
  //       },

  //     ]
  //   },

  // ]

  return (
    <>
      <Drawer_customer />
      {heat_map_load ? (
        <div>
          <img src={loader_img} id={styles.loader} />
        </div>
      ) : 
      !heat_map_load && service_data?.length == 0 ? (
        <div>
          <img src={no_data} id={styles.no_data_icon} />
          <p>No Data Added</p>
        </div>
      ) : (
        <>
          {/* <div
            className="container-fluid"
            style={{ padingLeft: "5em", paddingRight: "1em" }}
          >
            <h6 id={styles.service_header}>Service Details</h6>

            <Paper
              sx={{
                mb: 2,
                // padding: "10px",
              }}
              id={styles.table_container}
            >
              <TableContainer
                //  sx={{height:"60vh"}}
                // id={styles.service_tbl}
              >
                <Table
                  sx={{
                    marginLeft: "4em",
                    //  ,height: "60vh"
                  }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow rowSpan={2}>
                      <TableCell
                        className={styles.first_header}
                        id={styles.header_name}
                      >
                        Site Name
                      </TableCell>

                      {service_data[0].service_data?.map((ele) => (
                        <TableCell
                          sx={{
                            border: "1px solid rgb(201, 201, 201)",
                            padding: "0px",
                          }}
                          rowSpan={2}
                          colSpan={3}
                        >
                          <TableRow
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              borderBottom: "1px solid rgb(201, 201, 201)",
                            }}
                          >
                            <div
                              id={styles.header_name}
                              style={{
                                textAlign: "center",
                                paddingBottom: "10px",
                              }}
                            >
                              {ele?.service_name?.replaceAll("_", " ")}
                            </div>
                          </TableRow>
                          <TableRow
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              gap: "20px",
                            }}
                          >
                            <div
                              id={styles.header_name}
                              style={{ padding: "10px" }}
                            >
                              Planned
                            </div>
                            <div
                              id={styles.header_name}
                              style={{ padding: "10px" }}
                            >
                              Scheduled
                            </div>
                            <div
                              id={styles.header_name}
                              style={{ padding: "10px" }}
                            >
                              Collected
                            </div>
                          </TableRow>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {service_data?.map((ele) => (
                      <TableRow sx={{ border: "1px solid rgb(201, 201, 201)" }}>
                        <TableCell
                          style={{
                            color: "black",
                            minWidth: "150px",
                            border: "1px solid rgb(201, 201, 201)",
                          }}
                        >
                          {ele?.site_name}
                        </TableCell>

                        {ele?.service_data?.map((el) => (
                          <>
                            <TableCell
                              sx={{ border: "1px solid rgb(201, 201, 201)" }}
                            >
                              {el?.planned}
                            </TableCell>
                            <TableCell
                              sx={{ border: "1px solid rgb(201, 201, 201)" }}
                            >
                              {el?.scheduled}
                            </TableCell>
                            <TableCell
                              sx={{ border: "1px solid rgb(201, 201, 201)" }}
                            >
                              {el?.collected}
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div> */}

 <div className ="container-fluid" style={{ padingLeft: "5em", paddingRight: "1em" }}>
         <h6 id={styles.service_header}>Service Details</h6>
<Paper
sx={{
  mb: 2,
  padding: "0px",
  marginLeft: "5%",
  marginTop: "1%",
  width: "94%",

}}
id={styles.table_container}
>
<TableContainer id={styles.service_tbl} >
  <Table   stickyHeader aria-label="sticky table">
    <TableHead
      sx={{
        backgroundColor: "#007cc3",
        color: "#444a4f !important",
        position: "sticky",
        top: 0,
        zIndex: "99",
      }}
    >
 
      <TableRow>
        <TableCell className={styles.first_header}   id={styles.th_unit1} style={{ textAlign: "left" }}>
          <h6
            className="h6 mt-2"
            id={styles.hub_title}

          >
           Site Name
          </h6>
        </TableCell>
       {service_data[0]?.service_data?.map((ele)=>(
        <TableCell sx={{border:"1px solid rgb(201, 201, 201)",padding:"0px"}} rowSpan={2} colSpan={3}>
         
        <TableRow sx={{display:"flex",justifyContent:"center",borderBottom:"1px solid rgb(201, 201, 201)"}}>
          <div id={styles.header_name} style={{textAlign:"center",paddingBottom:'10px'}}>{ele?.service_name?.replaceAll("_"," ")}</div> 
        </TableRow>
        <TableRow sx={{display:"flex",justifyContent:"space-around",gap:"20px"}}>
       <div id={styles.header_name} style={{padding: "10px"}}>Planned</div> 
       <div id={styles.header_name} style={{padding: "10px"}}>Scheduled</div> 
       <div id={styles.header_name} style={{padding: "10px"}}>Collected</div> 
                 
           </TableRow>
       </TableCell>
       ))} 

      </TableRow>
    </TableHead>
    {service_data?.length == "0" ? (
      <TableBody>
        <TableRow>
          <TableCell id={styles.td_unit} colSpan={11}>
            <p style={{ textAlign: "center", padding: "0px" }}>
              No Data Available
            </p>
          </TableCell>
    
        </TableRow>
      </TableBody>
    ) : (
      <TableBody>
      {(rowsPerPage > 0
                  ? service_data?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : service_data
                )?.map((ele)=>(
       <TableRow sx={{border:"1px solid rgb(201, 201, 201)"}}>
         <TableCell  style={{color:'black',minWidth:'150px' ,border:"1px solid rgb(201, 201, 201)"}}>{ele?.site_name}</TableCell>

         {ele?.service_data?.map((el)=>(

           <>
            <TableCell  sx={{border:"1px solid rgb(201, 201, 201)"}}>{el?.planned}</TableCell> 
            <TableCell sx={{border:"1px solid rgb(201, 201, 201)"}}>{el?.scheduled}</TableCell> 
            <TableCell sx={{border:"1px solid rgb(201, 201, 201)"}}>{el?.collected}</TableCell> 

             </>

         ))}

       </TableRow>
      ))}
      </TableBody>
    )}
  </Table>
</TableContainer>
<TablePagination
id={styles.footer_div}
class="footer_div"
sx={{ backgroundColor: "#007cc3", color: "white" }}
rowsPerPageOptions={[10, 25, 50, 100]}
component="div"
count={service_data?.length}
rowsPerPage={rowsPerPage}
page={page}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
/>
</Paper>

</div>
        </>
      )}

      <Footer />
    </>
  );
}

export default Service_on_customer;
