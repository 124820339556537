import React, { useEffect, useState } from "react";
import Drawer_customer from "../Components/Drawer";
import ReactSpeedometer from "react-d3-speedometer";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import styles from "../Styles/Leads.module.css";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Yearly_audit_tbl from "../Components/Yearly_audit_tbl";
import Audit_weekly from "../Components/Audit_weekly";
import CompositionExample from "../Components/Achievement_gauge";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useNavigate } from "react-router-dom";
import loader_img from "../Media/Business_chart.gif";
import no_data_icon from "../Media/no_data_icon2.png";
import Iris_footer from "../Components/Iris_footer";

import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";

function Monthly_summary() {
  const storage = window.sessionStorage;

  let base_url = "https://crm.equinoxlab.com/api1";
  const [from_date, setFrom_Date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [to_date, setTo_Date] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState([]);

  const formattedMonth = dayjs(new Date());
  let new_month = moment(formattedMonth.$d).format("MM");
  let new_year = moment(formattedMonth.$d).format("YYYY");

  const [selectedMonth, setSelectedMonth] = useState(dayjs(new Date()));
  const [send_month, setSend_month] = useState(new_month);
  const [send_year, setSend_year] = useState(new_year);

  const [achievement_data, setAchievement_Data] = useState([]);
  const [all_audits_data, setAll_Audits_Data] = useState([]);
  const [yearly_audits_data, setYearly_Audits_Data] = useState([]);

  const [current_tab, setCurrent_Tab] = useState("today");

  const [current_week, setCurrent_Week] = useState([]);
  const [last_week, setLast_week] = useState([]);

  const [audit_year, setAuditYear] = useState(dayjs(new Date()));

  const [achievement_load, setAchievement_Load] = useState(false);
  const [audits_load, setAudits_Load] = useState(false);
  const [yearly_load, setYearly_Load] = useState(false);
  const [weekly_load, setWeekly_load] = useState(false);

  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);

  const [audit_fromDate, setAudit_FromDate] = useState(new Date(y, m, 1));
  const [audit_toDate, setAudit_ToDate] = useState(new Date(y, m + 1, 0));

  const navigate = useNavigate();

  const get_all_audits_dashboard = async (start_date, end_date) => {
    setAudits_Load(true);
    setAll_Audits_Data([]);
    try {
      let res = await fetch(
        `${base_url}/Admin_Dashboard.svc/get_CLIENT_CENTRAL_ALL_AUDITS_DASHBOARD/${start_date}/${end_date}`
      );
      let data = await res.json();
      setAudits_Load(false);
      setAll_Audits_Data(data?.data);
    } catch (error) {
      setAudits_Load(false);
    }
  };

  const get_target_achievement = async () => {
    setAchievement_Load(true);
    try {
      let res = await fetch(
        `${base_url}/Admin_Dashboard.svc/get_TARGET_VS_ACHIEVEMENT_V1/${moment(
          new Date(audit_fromDate)
        ).format("YYYY-MM-DD")}/${moment(new Date(audit_toDate)).format(
          "YYYY-MM-DD"
        )}`
      );
      let data = await res.json();
      setAchievement_Load(false);
      setAchievement_Data(data);
    } catch (error) {
      setAchievement_Load(false);
    }
  };

  const get_all_audits_yearly = async (date) => {
    setYearly_Load(true);
    let new_date = moment(date?.$d)?.format("YYYY");
    try {
      let res = await fetch(
        `${base_url}/Admin_Dashboard.svc/get_ALL_AUDITS_YEARLY/${new_date}`
      );
      let data = await res.json();
      setYearly_Load(false);
      setYearly_Audits_Data(data?.data);
    } catch (error) {
      setYearly_Load(false);
    }
  };

  const get_weekly_audits = async () => {
    setWeekly_load(true);
    try {
      let res = await fetch(
        `${base_url}/Admin_Dashboard.svc/get_CURR_AND_PREV_WEEK_AUDIT_REPORT_GENERATED`
      );
      let data = await res.json();
      setWeekly_load(false);
      let this_week = [];
      let last_week = [];
      this_week.push(null);
      this_week.push(data?.CURRENT_WEEK_DATA?.Monday);
      this_week.push(data?.CURRENT_WEEK_DATA?.Tuesday);
      this_week.push(data?.CURRENT_WEEK_DATA?.Wednesday);
      this_week.push(data?.CURRENT_WEEK_DATA?.Thusday);
      this_week.push(data?.CURRENT_WEEK_DATA?.Friday);
      this_week.push(data?.CURRENT_WEEK_DATA?.Saturday);
      this_week.push(data?.CURRENT_WEEK_DATA?.Sunday);

      last_week.push(null);
      last_week.push(data?.PREV_WEEK_DATA?.Monday);
      last_week.push(data?.PREV_WEEK_DATA?.Tuesday);
      last_week.push(data?.PREV_WEEK_DATA?.Wednesday);
      last_week.push(data?.PREV_WEEK_DATA?.Thusday);
      last_week.push(data?.PREV_WEEK_DATA?.Friday);
      last_week.push(data?.PREV_WEEK_DATA?.Saturday);
      last_week.push(data?.PREV_WEEK_DATA?.Sunday);

      setCurrent_Week(this_week);
      setLast_week(last_week);
    } catch (error) {
      setWeekly_load(false);
    }
  };

  const handleDateChange = (new_val) => {
    if (new_val !== null) {
      setAudit_FromDate(new_val[0]);
      setAudit_ToDate(new_val[1]);
    } else {
      //   setParams("MTD");
      //   setRange_Flag(false);
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      setAudit_FromDate(new Date(y, m, 1));
      setAudit_ToDate(new Date(y, m + 1, 0));
    }
  };

  const handleTab = (val) => {
    setCurrent_Tab(val);
    if (val == "today") {
      setFrom_Date(moment(new Date()).format("YYYY-MM-DD"));
      setTo_Date(moment(new Date()).format("YYYY-MM-DD"));
      get_all_audits_dashboard(
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      );
    } else if (val == "yesterday") {
      var d = new Date();
      let new_date = d.setDate(d.getDate() - 1);
      setFrom_Date(moment(new_date).format("YYYY-MM-DD"));
      setTo_Date(moment(new_date).format("YYYY-MM-DD"));
      get_all_audits_dashboard(
        moment(new_date).format("YYYY-MM-DD"),
        moment(new_date).format("YYYY-MM-DD")
      );
    } else {
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);
      // setFrom_Date(moment(new Date()).format("YYYY-MM-DD"));
      // setTo_Date(moment(new Date()).format("YYYY-MM-DD"));
      get_all_audits_dashboard(
        moment(firstDay).format("YYYY-MM-DD"),
        moment(lastDay).format("YYYY-MM-DD")
      );
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date(y, m + 1, 0));
    }
  };

  const handleSelectDateRange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);

      let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
      let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");
      setFrom_Date(newStartDate);
      setTo_Date(newEndDate);
      get_all_audits_dashboard(newStartDate, newEndDate);
    } else {
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);
      setSelectedFromDate(firstDay);
      setSelectedToDate(lastDay);
      // const fromattedDate = dayjs(new Date());
      // let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
      // setFrom_Date(new_date);
      // setTo_Date(new_date);
      get_all_audits_dashboard(
        moment(firstDay).format("YYYY-MM-DD"),
        moment(lastDay).format("YYYY-MM-DD")
      );
    }
  };

  const handleYear = (date, dateString) => {
    if (date) {
      setAuditYear(date);
      get_all_audits_yearly(date);
    } else {
      setAuditYear(dayjs(new Date()));
      get_all_audits_yearly(dayjs(new Date()));
    }
  };

  const handleNavigate = () => {
    storage.setItem("audit_flag", "show_audit");
    navigate("/zomato_dashboard");
  };

  useEffect(() => {
    get_all_audits_dashboard(from_date, to_date);
    get_weekly_audits();
  }, []);

  useEffect(() => {
    get_target_achievement();
  }, [audit_fromDate]);

  useEffect(() => {
    get_all_audits_yearly(audit_year);
  }, []);

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last week",
      value: [
        startOfWeek(addWeeks(new Date(), -1)),
        endOfWeek(addWeeks(new Date(), -1)),
      ],
      placement: "left",
    },
    // {
    //   label: 'Last 7 days',
    //   value: [subDays(new Date(), 6), new Date()],
    //   placement: 'left'
    // },
    // {
    //   label: 'Last 30 days',
    //   value: [subDays(new Date(), 29), new Date()],
    //   placement: 'left'
    // },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
    // {
    //   label: 'Last week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
    //     ];
    //   },
    //   appearance: 'default'
    // },
    // {
    //   label: 'Next week',
    //   closeOverlay: false,
    //   value: value => {
    //     const [start = new Date()] = value || [];
    //     return [
    //       addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
    //       addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
    //     ];
    //   },
    //   appearance: 'default'
    // }
  ];

  return (
    <>
      <Drawer_customer />
      <div
        className="container-fluid"
        style={{ paddingLeft: "5em", marginTop: "-20px", paddingRight: "1em" }}
      >
        <div className="row">
          <div className="col-4" id={styles.left_part}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h4 style={{ textAlign: "left" }}>Dashboard</h4>
              <p>Month Summary</p>
            </div>
            {achievement_load ? (
              <div id={styles.loader_contain}>
                <img src={loader_img} alt="" />
              </div>
            ) : (
              <div id={styles.achievement_container}>
                <div className="row">
                  <div className="col-4">
                    <p id={styles.audits}>
                      Audits <BorderColorOutlinedIcon id={styles.edit_icon} />
                    </p>
                  </div>
                  <div className="col-8">
                    <Space direction="vertical" id={styles.monthPicker}>
                      {/* <DatePicker
                        onChange={handleMonth}
                        picker="month"
                        value={selectedMonth}
                        format="MMM-YYYY"
                      /> */}
                      <DateRangePicker
                        // defaultValue={[new Date(), new Date()]}
                        format="dd MMM yyyy"
                        ranges={predefinedRanges}
                        value={[audit_fromDate, audit_toDate]}
                        onChange={handleDateChange}
                        size="md"
                        placement="bottomEnd"
                        preventOverflow
                      />
                    </Space>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-5" id={styles.target_info}>
                    <div>
                      <p style={{ marginLeft: "1vw" }}>
                        {achievement_data?.TOTAL_ACHIEVEMENT_COUNT}
                      </p>
                      <EmojiEventsOutlinedIcon
                        sx={{ color: "grey", fontSize: "17px" }}
                      />{" "}
                      <span>Total Audits Done</span>
                    </div>
                    <div>
                      <p style={{ marginLeft: "1vw", marginTop: "10px" }}>
                        {achievement_data?.TOTAL_TAGET_COUNT}
                      </p>
                      <p id={styles.agreed_text}>
                        {" "}
                        <AdsClickOutlinedIcon
                          sx={{ color: "grey", fontSize: "17px" }}
                        />{" "}
                        Agreed
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-7"
                    style={{ height: "140px", position: "relative" }}
                  >
                    {/* <ReactSpeedometer
                    maxValue={Number(achievement_data?.TOTAL_TAGET_COUNT)}
                    value={Number(achievement_data?.TOTAL_ACHIEVEMENT_COUNT)}
                    needleColor="red"
                    startColor="red"
                    segments={1}
                    width={200}
                    height={200}
                  /> */}
                    <CompositionExample data={achievement_data} />
                  </div>
                </div>
              </div>
            )}

            <div id={styles.audit_container}>
              <div className="row mt-3" id={styles.audits_show}>
                <div className="col-8">
                  <p>Recent Audits</p>
                </div>
                <div
                  className="col-4"
                  onClick={handleNavigate}
                  style={{ cursor: "pointer", zIndex: "999" }}
                >
                  <span>
                    View All{" "}
                    <ArrowForwardIosOutlinedIcon sx={{ fontSize: "12px" }} />
                  </span>
                </div>
              </div>
              <div className="row" id={styles.tab_container}>
                <div
                  className="col-sm-12 col-md-2"
                  style={{
                    borderBottom:
                      current_tab == "today" ? "2px solid #FF698D" : "",
                    color:
                      current_tab == "today"
                        ? "#FF698D"
                        : "var(--Secondary, #525256)",
                  }}
                  onClick={() => handleTab("today")}
                >
                  <p>Today</p>
                </div>
                <div
                  className="col-sm-12 col-md-3"
                  style={{
                    borderBottom:
                      current_tab == "yesterday" ? "2px solid #FF698D" : "",
                    color:
                      current_tab == "yesterday"
                        ? "#FF698D"
                        : "var(--Secondary, #525256)",
                  }}
                  onClick={() => handleTab("yesterday")}
                >
                  <p>Yesterday</p>
                </div>
                <div
                  className="col-sm-12 col-md-2"
                  style={{
                    borderBottom:
                      current_tab == "custom" ? "2px solid #FF698D" : "",
                    color:
                      current_tab == "custom"
                        ? "#FF698D"
                        : "var(--Secondary, #525256)",
                  }}
                  onClick={() => handleTab("custom")}
                >
                  <p>Custom</p>
                </div>
              </div>

              {current_tab == "custom" ? (
                <div className="row mt-3">
                  <DateRangePicker
                    // defaultValue={[new Date(), new Date()]}
                    format="dd MMM yyyy"
                    ranges={predefinedRanges}
                    value={[selectedFromDate, selectedToDate]}
                    onChange={handleSelectDateRange}
                    style={{ width: "300px" }}
                    size="sm"
                    placement="bottomEnd"
                    preventOverflow
                  />
                </div>
              ) : (
                <></>
              )}

              {audits_load ? (
                <div id={styles.loader_contain}>
                  <img src={loader_img} alt="" />
                </div>
              ) : !audits_load && all_audits_data?.length == 0 ? (
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="col-6 mt-5">
                    <img
                      src={no_data_icon}
                      alt=""
                      style={{ height: "200px", width: "200px" }}
                    />
                    <h4>No Data Available</h4>
                  </div>
                </div>
              ) : (
                <div
                  className="row mt-2"
                  id={
                    current_tab == "custom"
                      ? styles.audit_info_container
                      : styles.other_info_container
                  }
                >
                  {all_audits_data?.map((ele) => (
                    <>
                      <div className="col-6" id={styles.auditor_info}>
                        <h6>{ele?.RES_NAME}</h6>
                        <p>{ele?.CITY}</p>
                      </div>
                      <div className="col-6" id={styles.auditor_status}>
                        <h6>{ele?.AUDIT_STATUS}</h6>
                        <p>{ele?.AUDIT_DATE}</p>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-8">
            <div className="row">
              {yearly_load ? (
                <div id={styles.loader_contain}>
                  <img src={loader_img} alt="" />
                </div>
              ) : (
                <Yearly_audit_tbl
                  data={yearly_audits_data}
                  handleYear={handleYear}
                  audit_year={audit_year}
                />
              )}
            </div>
            {weekly_load ? (
              <div id={styles.loader_contain}>
                <img src={loader_img} alt="" />
              </div>
            ) : (
              <>
                {/* <div className="row" style={{ paddingLeft: "2vw" }}> */}

                <div
                  className="row mt-3 mb-5"
                  style={{
                    boxShadow:
                      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                    borderRadius: "5px",
                    margin: "0 0 0 2px",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "30px",
                    }}
                  >
                    <p id={styles.Statistic_header}>Statistics</p>
                    <p id={styles.weekly_header}>
                      Weekly comparison
                      {/* Weekly comparison <ExpandMoreOutlinedIcon /> */}
                    </p>
                  </div>
                  <Audit_weekly
                    current_week={current_week}
                    last_week={last_week}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Iris_footer />
    </>
  );
}

export default Monthly_summary;
