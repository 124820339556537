import React from "react";
import styles from "../Styles/Leads.module.css";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";

import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Tooltip } from "@mui/material";

function Leads_table({
  data,
  city_list,
  selected_City,
  setSelected_City,
  status_list,
  selected_Status,
  setSelected_Status,
  handleMonth,
  selectedMonth,
  city_list_audit,
  selected_city_audit,
  setSelected_City_Audit,
  status_list_audit,
  selected_status_audit,
  setSelected_Status_Audit,
  selected_agreement,
  setSelected_agreement,
  progress_data,
  isSelected,
  total_count
}) {
  const [progress, setProgress] = React.useState(60);
  const columns = [
    {
      name: isSelected?"AUDIT_DATE":"DATE_ADDED",
      label: isSelected?"Audit Date":"Date Added",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return <p id={styles.cityname}>{row}</p>;
        },
      },
    },
    {
      name: "RES_NAME",
      label: "Res Name",
      options: {
        filter: false,
        sort: true,
        // customBodyRender: (row, data) => {
        //   return <p id={styles.cityname}>{row}</p>;
        // },
      },
    },
    {
      name: "RES_ID",
      label: "Res ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "CITY",
      label: "City",
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (row, data) => {
        //   return <p id={styles.cityname}>{row}</p>;
        // },
      },
    },
    {
      name: "TYPE_OF_AGREEMENT",
      label: "Type Of Agreement",
      options: {
        filter: false,
        sort: false,
        display: isSelected,
        customBodyRender: (row, data) => {
          return <p id={styles.agreement}>{row}</p>;
        },
      },
    },
    {
      name: isSelected?"AUDIT_STATUS":"STATUS",
      label: isSelected?"Audit Status":"Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row, data) => {
          return <p style={{fontWeight:600}}>{row}</p>;
        },
      },
    },
    {
      name: "REPORT_LOCATION",
      label: "Download",
      options: {
        filter: false,
        sort: false,
        display: isSelected,
        customBodyRender: (row)=>{
          if(row == "-"){
             return <ArrowDropDownIcon sx={{color:"grey"}}/>
          }else{
            return(
              <Tooltip title="Click here"><a href={row} target="_blank"><ArrowDropDownIcon sx={{color:"#fe688d"}}/></a></Tooltip>
            )
          }

        }
      },
    },
  ];

  const options = {
    filterType: "checkbox",
  };
  return (
    <div id="leads_tbl">
      <MUIDataTable
      title={<h4 id={styles.list_head}>All Data ({data?.length}) <span>Total Count:{total_count}</span></h4>}
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: true,
          filter: false,
          pagination: false,
          // rowsPerPageOptions: [10, 25, 50, 100],
          // tableBodyMaxHeight: "40vh",
          scroll: false,
          customToolbar: () => {
            return (
              <>
                {isSelected ? (
                  <select
                  style={{marginTop:"20px"}}
                    id={styles.agreement_drop}
                    name=""
                      value={selected_agreement}
                      onChange={(e)=>setSelected_agreement(e.target.value)}
                  >
                    {/* <option >Select State</option> */}
                    <option value="ALL" selected >Type of Agreement</option>
                    <option value="SOA">SOA</option>
                    <option value="Upfront">Upfront</option>
                    <option value="Free">Free</option>
                    {/* {state_data?.map((ele) => (
                    <option value={ele.State_name}>{ele.State_name}</option>
                  ))} */}
                  </select>
                ) : (
                  <></>
                )}
               {!isSelected?<select
                  id={styles.payment_drop}
                  name=""
                  value={selected_Status}
                  onChange={(e)=>setSelected_Status(e.target.value)}
                >
                  <option value="ALL" selected >
                   All Status
                  </option>
                  {status_list?.map((ele) => (
                    <option value={ele.id}>{ele.text}</option>
                  ))}
                </select>:<select
                  id={styles.payment_drop}
                  name=""
                  value={selected_status_audit}
                  onChange={(e)=>setSelected_Status_Audit(e.target.value)}
                >
                  <option value="ALL" selected >
                   All Status
                  </option>
                  {status_list_audit?.map((ele) => (
                    <option value={ele.id}>{ele.text}</option>
                  ))}
                </select>}
               {!isSelected? <select
                  id={styles.city_drop}
                  name=""
                  value={selected_City}
                  onChange={(e) => setSelected_City(e.target.value)}
                >
                  <option value="ALL" selected >
                    All City
                  </option>
                  {city_list?.map((ele) => (
                    <option value={ele.text}>{ele.text}</option>
                  ))}
                </select>:<select
                  id={styles.city_drop}
                  name=""
                  value={selected_city_audit}
                  onChange={(e) => setSelected_City_Audit(e.target.value)}
                >
                  <option value="ALL" selected >
                   All City
                  </option>
                  {city_list_audit?.map((ele) => (
                    <option value={ele.text}>{ele.text}</option>
                  ))}
                </select>}
                <Space direction="vertical" id={styles.monthPicker2}>
                  <DatePicker
                    onChange={handleMonth}
                    picker="month"
                    value={selectedMonth}
                    format="MMM-YYYY"
                  />
                </Space>
                {isSelected ? (
                  <div className="row mt-4 mb-2" style={{ gap: "15px" }}>
              <div
                      className="col-3"
                      // style={{ marginLeft: "16.2vw" }}
                      id={styles.progress_bar_unit}
                    >
                      <div>
                        <p style={{fontSize:"12px"}}>SOA (Schedule {progress_data?.TOTAL_SOA_SCHEDULED_COUNT})</p>
                        <p style={{fontSize:"12px"}}>{progress_data?.TOTAL_SOA_COUNT}</p>
                      </div>
                      <Tooltip title={`Report Generated Count : ${progress_data?.TOTAL_SOA_REPORT_GENERATED_COUNT}`}>
                      <Box
                        sx={{
                          bgcolor: "white",
                          width: "100%",
                          marginTop:"3px",
                          cursor:"pointer"
                        }}
                      >
                             <LinearProgress
                          determinate
                          variant="outlined"
                          size="sm"
                          thickness={20}
                          value={progress_data?.length == 0?0:(progress_data?.TOTAL_SOA_REPORT_GENERATED_COUNT == 0 && progress_data?.TOTAL_SOA_COUNT == 0)?0:100*progress_data?.TOTAL_SOA_REPORT_GENERATED_COUNT/progress_data?.TOTAL_SOA_COUNT}
                          // value={progress_data?.length == 0?0:progress_data?.TOTAL_SOA_COUNT*progress_data?.TOTAL_SOA_SCHEDULED_COUNT/100}
                          sx={{
                            "--LinearProgress-radius": "0px",
                            "--LinearProgress-progressThickness": "100%",
                            boxShadow: "sm",
                            borderColor: "rgb(202, 202, 202)",
                            backgroundColor: "#f3f1ff",
                            color: "#fe688d",
                          }}
                        >
                          {/* <Typography
          level="body-xs"
          fontWeight="xl"
          // textColor="common.white"
          sx={{ mixBlendMode: 'difference' ,color: 'red'}}
        >
          {`${Math.round(progress)}%`}
        </Typography> */}
                        </LinearProgress>
                      </Box>
                        </Tooltip> 
                    </div>
            
                    <div className="col-3" id={styles.progress_bar_unit}>
                      <div>
                        <p style={{fontSize:"12px"}}>Upfront (Schedule {progress_data?.TOTAL_UPFRONT_SCHEDULED_COUNT})</p>
                        <p style={{fontSize:"12px"}}>{progress_data?.TOTAL_UPFRONT_COUNT}</p>
                      </div>
                      <Tooltip title={`Report Generated Count : ${progress_data?.TOTAL_UPFRONT_REPORT_GENERATED_COUNT}`}> <Box
                        sx={{
                          bgcolor: "white",
                          width: "100%",
                              marginTop:"3px",
                              cursor:"pointer"
                        }}
                      >
                       <LinearProgress
                          determinate
                          variant="outlined"
                          size="sm"
                          thickness={20}
                          value={progress_data?.length == 0?0:(progress_data?.TOTAL_UPFRONT_REPORT_GENERATED_COUNT == 0 && progress_data?.TOTAL_UPFRONT_COUNT == 0)?0:100*progress_data?.TOTAL_UPFRONT_REPORT_GENERATED_COUNT/progress_data?.TOTAL_UPFRONT_COUNT}
                          // value={progress_data?.length == 0?0:progress_data?.TOTAL_UPFRONT_COUNT*progress_data?.TOTAL_UPFRONT_SCHEDULED_COUNT/100}
                          sx={{
                            "--LinearProgress-radius": "0px",
                            "--LinearProgress-progressThickness": "100%",
                            boxShadow: "sm",
                            borderColor: "rgb(202, 202, 202)",
                            backgroundColor: "#f3f1ff",
                            color: "#fe688d",
                          }}
                        >
                          {/* <Typography
          level="body-xs"
          fontWeight="xl"
          // textColor="common.white"
          sx={{ mixBlendMode: 'difference' ,color: 'red' }}
        >
          {`${Math.round(progress)}%`}
        </Typography> */}
                        </LinearProgress>
                      </Box>
                        </Tooltip>
                    </div>
                    <div className="col-3" id={styles.progress_bar_unit}>
                      <div>
                        <p style={{fontSize:"12px"}}>Free (Schedule {progress_data?.TOTAL_FREE_SCHEDULED_COUNT})</p>
                        <p style={{fontSize:"12px"}}>{progress_data?.TOTAL_FREE_COUNT}</p>
                      </div>
                      <Tooltip title={`Report Generated Count : ${progress_data?.TOTAL_FREE_REPORT_GENERATED_COUNT}`}> <Box
                        sx={{
                          bgcolor: "white",
                          width: "100%",
                          marginTop:"3px",
                          cursor:"pointer"
                        }}
                      >
                         <LinearProgress
                          determinate
                          variant="outlined"
                          size="sm"
                          thickness={20}
                          value={progress_data?.length == 0?0:(progress_data?.TOTAL_FREE_REPORT_GENERATED_COUNT == 0 && progress_data?.TOTAL_FREE_COUNT == 0)?0: 100*progress_data?.TOTAL_FREE_REPORT_GENERATED_COUNT/progress_data?.TOTAL_FREE_COUNT}
                          // value={progress_data?.length == 0?0:progress_data?.TOTAL_FREE_COUNT*progress_data?.TOTAL_FREE_SCHEDULED_COUNT/100}
                          sx={{
                            "--LinearProgress-radius": "0px",
                            "--LinearProgress-progressThickness": "100%",
                            boxShadow: "sm",
                            borderColor: "rgb(202, 202, 202)",
                            backgroundColor: "#f3f1ff",
                            color: "#fe688d",
                          }}
                        >
                          {/* <Typography
          level="body-xs"
          fontWeight="xl"
          // textColor="common.white"
          sx={{ mixBlendMode: 'difference' ,color: 'red' }}
        >
          {`${Math.round(progress)}%`}
        </Typography> */}
                        </LinearProgress>
                      </Box>
                        </Tooltip>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          },
          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
        }}
      />
    </div>
  );
}

export default Leads_table;
