import React, { useState } from 'react'
import styles from "../Styles/Modals.module.css"
function Iris_footer() {
    const [year,setYear] = useState(new Date())
    let info_arr = year?.toString()?.split(" ")
  return (
    <div id={styles.footer}>
    <footer class="footer" role="contentinfo" >
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-md-7" style={{marginTop: "0.4%"}}>
                <p class="copyright" style={{color: "grey"}}>
                   Copyright &copy; {info_arr[3]} Equinox Labs Private Limited
                </p>
              </div>
            </div>
          </div>
        </footer>
        </div>
  )
}

export default Iris_footer