import React, { useEffect, useState } from "react";
import equinoxLogo from "../Media/equinox_logo.png";
// import equinoxLogo from "../Media/equinox-logo.webp";
import hubcslogo from "../Media/ECC Logo.png";
import styles from "../Styles/LoginStyle.module.css";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Iris_footer from "../Components/Iris_footer";



function AccountsList({base_url}) {
  const navigate = useNavigate();
  const storage = window.sessionStorage;
  const token = storage.getItem("token");
  const profile = JSON.parse(storage.getItem("profile"));
  const number = storage.getItem("login_mobile_no");
  const customer = JSON.parse(storage?.getItem("customer"));
  const count = storage.getItem("count");

  const [accData, setAccData] = useState([]);
  const [load, setLoad] = useState(false);
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  const get_accounts = async () => {
    setLoad(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("login_mobile_no", `${number}`);
    axios
      .post(`${base_url}/get-customer-list`, formData, {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "Multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setLoad(false);
        setAccData(res?.data?.customer);

        storage.setItem("count", res?.data?.customer?.length);
      })
      .catch((err) => {
        
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Something went wrong.",
        });
      });
  };

  useEffect(() => {
    if(token != "" && token != null){
      get_accounts();
    }
  }, []);

  useEffect(() => {
    if (token == "" || token == null) {
      navigate("/");
    }
  }, [storage]);

  const handleClick = (ele) => {
    storage.setItem("customer_id",ele?.customer_id)
    storage.setItem("customer_name",ele?.capital_customer_name)
    navigate("/dashboard")
  }

  return (
    <>
      <div className="container-fluid p-4">
        {/* <div 
    // id={styles.main_header} 
    className="container-fluid mt-2">
        <div
        //  id={styles.iconContain}
          className="col-md-5">
          <i
            className="fa-solid fa-user"
            // id={styles.user}
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
            // onClick={handleOpen2}
          ></i>
          <i
            className="fa-solid fa-gear"
            // id={styles.setting}
            // onClick={() => swal("Please Select Account!!")}
          ></i>
          <Tooltip title="Sign Out" placement="bottom">
            <div 
            // id={styles.logout_btn} onClick={handleLogout}
            >
              <PowerSettingsNewIcon sx={{ height: "20px" }} />
            </div>
          </Tooltip>
        </div> 
         </div> */}

        <div className="row">
          <div className={`${styles.equinoxLogo2} col-md-6 col-sm-12`}>
            <img src={equinoxLogo} alt="" />
          </div>

          <div className={`${styles.customerhubLogo} col-md-6 col-sm-12`}>
            <img src={hubcslogo} alt="" />
          </div>

          
          {/* <div
            className={`${styles.equinoxLogo} col-md-6 col-sm-12`}
            style={{ justifyContent: "flex-end", color: "#44546A" }}
          >
            <h4>Customer HUB</h4>
          </div> */}
        </div>
        <div style={{marginBottom:"8vh"}}>
          <h6 id={styles.header_msg}>
            Welcome {profile?.capital_customer_name}, <br />
            There are {count} Accounts registered on your number, Please Select
            an Account to process further. <br />
          </h6>
        </div>

        {load ? (
          // <Account_load />
          <>LOADING....</>
        ) : (
          <>
            {" "}
            <div style={{display:"flex", justifyContent:"center"}}>
              <div className="col-md-6">
                <div className="col-md-12" style={{maxHeight:"54vh",overflow:"auto"}}>
                  {accData?.map((ele, index) => (
                    <>
                     <div
                        id={styles.name_contain}
                      >
                        <h2
                          style={{display:"flex", justifyContent:"space-between", gap:"2vw"}}
                          onClick={()=>handleClick(ele)}
                        >
                          <span 
                          >
                            {ele?.capital_customer_name}
                            </span>
                            <PlayArrowIcon/>
                            {/* <span style={{fontSize:"20px"}}>&#11208;</span> */}
                            </h2>                       
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            
          </>
        )}
    </div>
    
        {/* <div id={styles.footer_container}>
          <div className="col-12">
            <div className="row" id={styles.footerCopyright}>
              <div className="col-9">
                  <p id={styles.copyright}>

                    Copyright © {info_arr[3]} Equinox Labs Private Limited
                  </p>
              </div>
            </div>
          </div>
        </div> */}
        <Iris_footer/>
    </>
  );
}

export default AccountsList;
