import React, { useEffect, useState } from "react";
import styles from "../Styles/Dashboard.module.css";
import Schedule_tbl from "../Components/Schedule_tbl";
import { useNavigate } from "react-router-dom";
import ServicesPlans from "../Components/ServicesPlans";
import MenuAppBar from "../Components/Header";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import Drawer_customer from "../Components/Drawer";
import Add_query from "../Modals/Add_query";
import loader_img from "../Media/Business_chart.gif"

function Footer() {
    const storage = window.sessionStorage;
    let token = storage.getItem("token");
    let profile = JSON?.parse(storage.getItem("profile"));
    let support_details = JSON?.parse(storage.getItem("support_details"));
    
    
  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");
  
    // load usestates
  
  return (
    <>
      <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
                  {/* <div style={{position:"absolute", bottom: "0px",right: "40%"}}> */}
                    <p id={styles.copyright}>

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
                  {/* </div> */}
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <h6><span>KAE Name</span>: {support_details?.planner_name}</h6>
                       <h6><span>Mobile</span>: {support_details?.planner_number}</h6>
                       <h6><span>Email</span>: {support_details?.planner_email}</h6>
                    </div>
                    <div>
                       <h6><span>Sales Person Name</span>: {support_details?.salesperson_name}</h6>
                       <h6><span>Mobile</span>: {support_details?.salesperson_number}</h6>
                       <h6><span>Email</span>: {support_details?.salesperson_email}</h6>
                    </div>
                </div>
              </div>

          </div>
    </>
  );
}

export default Footer;
