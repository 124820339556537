import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

function Audit_weekly({ current_week, last_week }) {
  let xLabels = ["", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  return (
    <div id="audit_graph">
      <BarChart
        xAxis={[{ scaleType: "band", data: xLabels }]}
        series={[
          { data: last_week, label: "Last Week", color: "var(--Gray-05, #E8E8E8)" },
          { data: current_week, label: "This Week", color: "#fe688d" },
        ]}
        width={700}
        height={300}
      />
    </div>
  );
}

export default Audit_weekly;
